import type { TextDropdownOption } from 'common';
import { Modal, P, TextDropdown, ToastBanner } from 'common';
import { CoinComponentObject } from 'common/helper';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { COIN_TYPES } from 'utils/const';
import {
    getCryptoNetworks,
    getCurrencyCryptoNetwork,
    getDepositARSInfo,
    getDepositCryptoInfo,
} from '../walletService';
import { getCoins } from '../../../../common/commonService';
import { getSingleCoinIDFromName } from '../../../../utils/currency';
import type { CurrencyCryptoNetwork, CryptoNetworks } from '../types';
import type { Coin } from '../../../../common/types';
import * as _ from 'lodash';
import { REQUEST_STATE } from '../../../../redux/types';
import { LoaderWheel } from '../../../../common/Loader/LoaderWheel';

interface DepositModalProps {
    modalData: string;
}

export const DepositModal = (props: DepositModalProps) => {
    const dispatch = useAppDispatch();
    const { modalOpen } = useAppSelector((state) => state.modal);
    const { modalData } = props;
    const { depositCrypto } = useAppSelector((state) => state.wallet);
    const { coins } = useAppSelector((state) => state.common);
    const { coins: coinsReq } = useAppSelector(
        (state) => state.common.requestStates,
    );
    const [cryptoNetwork, setCryptoNetwork] = useState('');
    const [currentNetwork, setCurrentNetwork] = useState('');
    const { currencyCryptoNetwork, cryptoNetworks } = useAppSelector(
        (state) => state.wallet,
    );

    const [dropdownOptions, setDropdownOptions] = useState<any>([]);

    const {
        getCurrencyCryptoNetwork: currencyCryptoNetworkReq,
        getCryptoNetworks: cryptoNetworksReq,
        depositCrypto: depositCryptoReq,
    } = useAppSelector((state) => state.wallet.requestStates);

    const isLoading =
        currencyCryptoNetworkReq === REQUEST_STATE.LOADING ||
        cryptoNetworksReq === REQUEST_STATE.LOADING ||
        coinsReq === REQUEST_STATE.LOADING;

    const getCryptoNetwork = (
        cryptoNetworks: CryptoNetworks[],
        currencyCryptoNetwork: CurrencyCryptoNetwork[],
        currentCoin: string,
        coins: Coin[],
    ): TextDropdownOption[] => {
        const current = getSingleCoinIDFromName(coins, currentCoin);
        const currencyCryptoNetworkFiltered = currencyCryptoNetwork.filter(
            (item) => item.currencyId === current,
        );
        return _.compact(
            cryptoNetworks.map((item) => {
                const current = currencyCryptoNetworkFiltered.find(
                    (item2) => item2.cryptonetworkId === item.id,
                );
                if (!current) return null;
                return {
                    text: item.name,
                    id: current.currencyId.toString(),
                };
            }),
        );
    };

    useEffect(() => {
        dispatch(getDepositARSInfo());
        dispatch(getCoins());
        dispatch(getCurrencyCryptoNetwork());
        dispatch(getCryptoNetworks());
    }, []);

    const closeModal = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
    };

    useEffect(() => {
        if (cryptoNetworks && currencyCryptoNetwork && modalData && coins) {
            setDropdownOptions(
                getCryptoNetwork(
                    cryptoNetworks,
                    currencyCryptoNetwork,
                    modalData,
                    coins,
                ),
            );
        }
    }, [cryptoNetworks, currencyCryptoNetwork, modalData, coins]);

    useEffect(() => {
        if (depositCrypto) setCryptoNetwork(depositCrypto?.address);
    }, [depositCrypto]);

    if (modalData === '') {
        return (
            <Modal
                open={modalOpen === MODAL_OPEN.DEPOSIT_MODAL}
                onClose={() => {
                    closeModal();
                }}
            >
                <LoaderWheel />
            </Modal>
        );
    }
    return (
        <Modal
            className='wallet-modal-open'
            title={
                modalData &&
                `Depositar ` +
                    CoinComponentObject[modalData.toString()].text +
                    ' (' +
                    modalData +
                    ')'
            }
            open={modalOpen === MODAL_OPEN.DEPOSIT_MODAL}
            onClose={() => {
                setCurrentNetwork('');
                setCryptoNetwork('');
                closeModal();
            }}
            noPadding
        >
            {modalData === COIN_TYPES.ARS ? (
                <div className='deposit-modal-container'>
                    <ToastBanner
                        show
                        message='Podes ingresar hasta ? ARS mensuales'
                    />
                    <div
                        className='modal-ars-data'
                        style={{ marginTop: '20px' }}
                    >
                        <strong>CUIT</strong>
                        <P>20-1-735131-1-5</P>
                        <strong>CBU</strong>
                        <P>3220001805007351310010</P>
                        <strong>ALIAS</strong>
                        <P>BISBULL</P>
                        <strong>Banco</strong>
                        <P>No disponible</P>
                        <strong>Cuenta</strong>
                        <P>735131</P>
                    </div>
                </div>
            ) : (
                <div className='deposit-modal-container'>
                    <ToastBanner show message='Elige la red a depositar' />
                    <div style={{ marginTop: '20px' }}>
                        <strong>Red: </strong>
                        <div className='modal-crypto-dropdown'>
                            <TextDropdown
                                loading={isLoading}
                                noDataText='No hay datos'
                                value={currentNetwork}
                                onChange={(e) => {
                                    setCurrentNetwork(e.id);
                                    dispatch(
                                        getDepositCryptoInfo({
                                            cryptonetwork_id:
                                                parseInt(currentNetwork),
                                            currency_id: parseInt(e.id),
                                        }),
                                    );
                                }}
                                options={dropdownOptions}
                                backgroundColor
                            />
                        </div>
                        <>
                            <strong>Direccion: </strong>
                            {depositCryptoReq === REQUEST_STATE.LOADING ? (
                                <LoaderWheel />
                            ) : (
                                <P>{cryptoNetwork || 'Seleccione una red'}</P>
                            )}
                        </>
                    </div>
                </div>
            )}
        </Modal>
    );
};
