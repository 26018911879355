import { Button } from '@common';
import { SessionCard } from '../components/SessionCard';
import { getTokensFromUrl } from '@utils';
import passwordImage from 'assets/images/recover_password_side_image.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { registerActivate } from '../sessionService';
import { REQUEST_STATE } from 'redux/types';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'layout/routes/const';
import { useState } from 'react';
import { FIRST_TIME_2FA } from 'utils/axios/index';

export const RegisterActivate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { requestStates } = useAppSelector((state) => state.session);
    const { pathname } = useLocation();
    const [isError, setIsError] = useState(false);

    const { uid, token } = getTokensFromUrl(
        ROUTE_PATH.REGISTER_ACTIVATION,
        pathname,
    );

    const handleSubmit = () => {
        dispatch(
            registerActivate({
                uid,
                token,
            }),
        )
            .unwrap()
            .then(() => {
                toast.success(
                    'Tu cuenta está validada, ya puede ingresar a su cuenta con su mail y contraseña',
                );
                navigate('/login');
                localStorage.setItem(FIRST_TIME_2FA, 'true');
            })
            .catch(() => {
                setIsError(true);
                toast.error(
                    'Verifique que la cuenta ya se encuentre activada. En caso contrario contacte el administrador.',
                );
            });
    };

    return (
        <div>
            <div className='login-card'>
                <SessionCard
                    title='Activación de tu cuenta'
                    subtitle='Clickea en el botón para confirmar la activación de tu cuenta.'
                    src={passwordImage}
                    onSubmit={handleSubmit}
                >
                    <Button
                        style={{ width: 150, marginLeft: 0 }}
                        disabled={isError}
                        type='submit'
                        loading={
                            requestStates.registerActivate ===
                            REQUEST_STATE.LOADING
                        }
                    >
                        Activar
                    </Button>
                </SessionCard>
            </div>
        </div>
    );
};
