import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import type { RootState } from 'redux/store';
import { ALL_URL } from 'redux/url';

enum PHOTO_UPLOAD_REQUEST {
    SET_USER_PHOTO_FACE = 'SET_USER_PHOTO_FACE',
    SET_USER_PHOTO_ID = 'SET_USER_PHOTO_ID',
    SET_USER_PHOTO_SELFIE = 'SET_USER_PHOTO_SELFIE',
    GET_USER_PHOTO_FACE = 'GET_USER_PHOTO_FACE',
    GET_USER_PHOTO_ID = 'GET_USER_PHOTO_ID',
    GET_USER_PHOTO_SELFIE = 'GET_USER_PHOTO_SELFIE',
}

interface UserPhotoFaceParams {
    image: File;
}

export const setUserPhotoFace = createAsyncThunk<
    Record<string, never>,
    UserPhotoFaceParams
>(
    PHOTO_UPLOAD_REQUEST.SET_USER_PHOTO_FACE,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const formdata = new FormData();
            formdata.append('image', params.image);
            const { data } = await axios.post(
                process.env.REACT_APP_PUBLIC_URL +
                    '/' +
                    ALL_URL.USER_PHOTO_FACE,
                formdata,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-Type': 'multipart/form-data;',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface UserPhotoIdParams {
    image: File;
}

export const setUserPhotoId = createAsyncThunk<
    Record<string, never>,
    UserPhotoIdParams
>(
    PHOTO_UPLOAD_REQUEST.SET_USER_PHOTO_ID,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const formdata = new FormData();
            formdata.append('image', params.image);
            const { data } = await axios.post(
                process.env.REACT_APP_PUBLIC_URL + '/' + ALL_URL.USER_PHOTO_ID,
                formdata,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-Type': 'multipart/form-data;',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface UserPhotoSelfieParams {
    image: File;
}

export const setUserPhotoSelfie = createAsyncThunk<
    Record<string, never>,
    UserPhotoSelfieParams
>(
    PHOTO_UPLOAD_REQUEST.SET_USER_PHOTO_SELFIE,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const formdata = new FormData();
            formdata.append('image', params.image);
            const { data } = await axios.post(
                process.env.REACT_APP_PUBLIC_URL +
                    '/' +
                    ALL_URL.USER_PHOTO_SELFIE,
                formdata,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-Type': 'multipart/form-data;',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export const getUserPhotoFace = createAsyncThunk<string>(
    PHOTO_UPLOAD_REQUEST.GET_USER_PHOTO_FACE,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const { data } = await axios.get(
                process.env.REACT_APP_PUBLIC_URL +
                    '/' +
                    ALL_URL.USER_PHOTO_FACE,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-type': 'multipart/form-data',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export const getUserPhotoId = createAsyncThunk<string>(
    PHOTO_UPLOAD_REQUEST.GET_USER_PHOTO_ID,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const { data } = await axios.get(
                process.env.REACT_APP_PUBLIC_URL + '/' + ALL_URL.USER_PHOTO_ID,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-type': 'multipart/form-data',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export const getUserPhotoSelfie = createAsyncThunk<string>(
    PHOTO_UPLOAD_REQUEST.GET_USER_PHOTO_SELFIE,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access = (getState() as RootState).session.tempAccessToken;
            const { data } = await axios.get(
                process.env.REACT_APP_PUBLIC_URL +
                    '/' +
                    ALL_URL.USER_PHOTO_SELFIE,
                {
                    headers: {
                        Authorization: `JWT ${access}`,
                        'Content-type': 'multipart/form-data',
                    },
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
