/**
 *   Intercept and refresh expired tokens for multiple requests
 *   Used https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f for this implemenation,
 *   but customized for Bisbull needs.
 *   Added types for TypeScript support.
 */

import { AUTHENTICATED, ACCESS_TOKEN, REFRESH_TOKEN } from './index';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import { ALL_URL } from 'redux/url';
interface QueueItem {
    resolve: (value: unknown) => void;
    reject: (reason?: unknown) => void;
}

/**
 * shouldIntercept
 * ===============
 * @description check if the request should be intercepted
 * @param error - axios error
 * @returns boolean - true if the request response is error 401
 */
const shouldIntercept = (error: any) => {
    try {
        return error.response.status === 401;
    } catch (e) {
        return false;
    }
};

interface TokenData {
    accessToken: string;
    refreshToken: string;
}

/**
 * setTokenData
 * ===============
 * @description set token data in local storage and set authenticated true
 * @param tokenData - access and refresh token
 * @param axiosClient - axios instance
 * @returns void
 */
const setTokenData = (tokenData: TokenData, axiosClient: AxiosInstance) => {
    localStorage.setItem(REFRESH_TOKEN, tokenData.refreshToken);
    localStorage.setItem(ACCESS_TOKEN, tokenData.accessToken);
    localStorage.setItem(AUTHENTICATED, 'true');
};

/**
 * handleTokenRefresh
 * ===============
 * @description refresh access token using refresh token and set new tokens in local storage
 * @returns promess with access and refresh token
 */
const handleTokenRefresh = () => {
    const refresh = localStorage.getItem(REFRESH_TOKEN);
    return new Promise<TokenData>(async (resolve, reject) => {
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_PUBLIC_URL + '/' + ALL_URL.REFRESH,
                { refresh },
            );
            const tokenData: TokenData = {
                accessToken: data.access,
                refreshToken: data.refresh,
            };
            resolve(tokenData);
        } catch (err: unknown) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(AUTHENTICATED);
            window.location.href = '/';
            reject(err);
        }
    });
};

//not using attachTokenToRequest, axios instance used instead

/**
 * axiosInterceptor
 * ===============
 * @description axios interceptor for refreshing tokens
 * @param axiosClient - axios instance
 * @param customOptions - custom options for axios interceptor if needed. Not used in this project
 * @returns void
 */
export const axiosInterceptorHelper = (
    axiosClient: AxiosInstance,
    customOptions = {},
) => {
    let isRefreshing = false;
    let failedQueue: QueueItem[] = [];

    // add authorization if has it, request that do not need it wont matter.
    axiosClient.interceptors.request.use(
        async (config) => {
            const access = localStorage.getItem(ACCESS_TOKEN);
            if (access) {
                config.headers = {
                    ...config.headers,
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                    authorization: `JWT ${access}`,
                };
            }

            return config;
        },
        (error) => Promise.reject(error),
    );

    const options = {
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };

    /**
     * processQueue
     * ===============
     * @description process queue of requests that are waiting for the token to refresh
     * @param error - axios error
     * @param token - access token
     * @returns void
     */
    const processQueue = (error: unknown, token: string | null) => {
        failedQueue.forEach((prom) => {
            if (error) prom.reject(error);
            else prom.resolve(token);
        });
        failedQueue = [];
    };

    /**
     * interceptor
     * ===============
     * @description axios interceptor for refreshing tokens
     * @param error - axios error
     * @returns promess with access and refresh token
     */
    const interceptor = (error: any): Promise<unknown> => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }
        if (error.config._retry || error.config._queued)
            return Promise.reject(error);
        const originalRequest = error.config;

        // if is refreshing, add request to queue
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(() => {
                    originalRequest._queued = true;
                    return axiosClient.request(originalRequest);
                })
                .catch(() => {
                    // Ignore refresh token request's "err" and return actual "error" for the original request
                    return Promise.reject(error);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        // refresh token
        return new Promise<AxiosResponse<string, any>>((resolve, reject) => {
            options.handleTokenRefresh
                .call(options.handleTokenRefresh)
                .then((tokenData) => {
                    // insted of options.attachTokenToRequest
                    options.setTokenData(tokenData, axiosClient);
                    processQueue(null, tokenData.accessToken);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err: unknown) => {
                    processQueue(err, null);
                    reject(err);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    };

    // add interceptor
    axiosClient.interceptors.response.use(undefined, interceptor);
};
