import { Button, P } from 'common';
import { Col, Form, Row } from 'reactstrap';
import { MarketInput } from '../MarketInput/MarketInput';
import { dotAndComma, formatPrice, getBuyAndSellPrices } from 'utils/currency';
import Big from 'big.js';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { toast } from 'react-toastify';
import { getBalanceExtended, getTokenPairPrices } from 'common/commonService';
import {
    addExchangeLimit,
    getExchangeOrders,
} from '../../../quotation/quotationService';
import { ORDER_SIDE } from '../../../quotation/types';
import { formatNumberWithCommas } from 'utils/string';

interface LimitTabProps {
    currentCoinId: string | null;
}

export const LimitTab = (props: LimitTabProps) => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );
    const currentTokenPairPrice = tokenPairPrices
        ? tokenPairPrices.find(
              (tpp) => tpp.tokenpairId.toString() === props.currentCoinId,
          )
        : null;
    const tokenPairBaseBalance = currentTokenPairPrice?.baseBalance;
    const tokenPairQuoteBalance = currentTokenPairPrice?.quoteBalance;

    const { requestStates } = useAppSelector((state) => state.quotation);
    const loading = requestStates.addExchangeLimit === 'LOADING';

    const buyFormik = useFormik({
        initialValues: {
            price: '',
            target: '',
            amount: '',
            total: '',
        },
        onSubmit: (values) => {
            if (!currentTokenPairPrice) {
                toast.error('Hubo un error, vuelve a intentar más tarde.');
                return;
            }
            dispatch(
                addExchangeLimit({
                    amount: values.amount,
                    order_side: ORDER_SIDE.BUY,
                    tokenpair_id: currentTokenPairPrice.tokenpairId,
                    price_target: values.target,
                    amount_in: currentTokenPairPrice.quoteCode,
                    symbol: currentTokenPairPrice.symbol,
                }),
            )
                .unwrap()
                .then(() => {
                    buyFormik.resetForm();
                    toast.success('Su orden de limit se realizó con éxito!');
                    dispatch(getExchangeOrders());
                    dispatch(getBalanceExtended());
                    dispatch(getTokenPairPrices());
                })
                .catch(() => {
                    toast.error(
                        'Su orden de limit no se pudo realizar, vuelve a intentar más tarde.',
                    );
                });
        },
    });
    const sellFormik = useFormik({
        initialValues: {
            price: '',
            target: '',
            amount: '',
            total: '',
        },
        onSubmit: (values) => {
            if (!currentTokenPairPrice) {
                toast.error('Hubo un error, vuelve a intentar más tarde.');
                return;
            }
            dispatch(
                addExchangeLimit({
                    amount: values.amount,
                    order_side: ORDER_SIDE.SELL,
                    tokenpair_id: currentTokenPairPrice.tokenpairId,
                    price_target: values.target,
                    amount_in: currentTokenPairPrice.baseCode,
                    symbol: currentTokenPairPrice.symbol,
                }),
            )
                .unwrap()
                .then(() => {
                    sellFormik.resetForm();
                    toast.success('Su orden de limit se realizó con éxito!');
                    dispatch(getExchangeOrders());
                    dispatch(getBalanceExtended());
                    dispatch(getTokenPairPrices());
                })
                .catch(() => {
                    toast.error(
                        'Su orden de limit no se pudo realizar, vuelve a intentar más tarde.',
                    );
                });
        },
    });

    const getBuyDisabled = () => {
        return !buyFormik.values.target || !buyFormik.values.amount || loading;
    };

    const getSellDisabled = () => {
        return (
            !sellFormik.values.target || !sellFormik.values.amount || loading
        );
    };

    const _limitAmount = (value: string, isSelling: boolean) => {
        if (!tokenPairBaseBalance || !tokenPairQuoteBalance) return '0';
        const maxBalance = isSelling
            ? Big(tokenPairBaseBalance)
            : Big(tokenPairQuoteBalance);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };

    const _isStringValueValid = (v: string) => !!v && Big(v).gt(0);

    const handleBuyChange = (values: {
        price: string;
        target: string;
        amount: string;
        total: string;
    }) => {
        if (!currentTokenPairPrice) return;
        let amount = values.amount;
        let equivalentToReceive = values.total;
        if (_isStringValueValid(values.amount)) {
            amount = _limitAmount(values.amount, false);
            if (_isStringValueValid(values.target)) {
                equivalentToReceive = getBuyAndSellPrices(
                    currentTokenPairPrice,
                    amount,
                    values.target,
                ).buy.toFixed(8);
            }
        }
        buyFormik.setValues({
            price: values.price,
            target: values.target,
            amount: amount,
            total: equivalentToReceive,
        });
    };

    const handleSellChange = (values: {
        price: string;
        target: string;
        amount: string;
        total: string;
    }) => {
        if (!currentTokenPairPrice) return;
        let amount = values.amount;
        let equivalentToReceive = values.total;
        if (_isStringValueValid(values.amount)) {
            amount = _limitAmount(values.amount, true);
            if (_isStringValueValid(values.target)) {
                equivalentToReceive = getBuyAndSellPrices(
                    currentTokenPairPrice,
                    amount,
                    values.target,
                ).sell.toFixed(8);
            }
        }
        sellFormik.setValues({
            price: values.price,
            target: values.target,
            amount,
            total: equivalentToReceive,
        });
    };

    if (!currentTokenPairPrice) return null;
    return (
        <Row className='input-card-tab-container'>
            <Col>
                <Form onSubmit={buyFormik.handleSubmit}>
                    <Row className='mb-2'>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Disponible
                            </P>
                        </Col>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'right',
                                }}
                            >
                                {currentTokenPairPrice.quoteCode === 'USDT'
                                    ? formatNumberWithCommas(
                                          formatPrice(
                                              tokenPairQuoteBalance,
                                          ).toString(),
                                          currentTokenPairPrice.quoteCode,
                                          true,
                                      )
                                    : dotAndComma(
                                          formatPrice(tokenPairQuoteBalance),
                                      )}{' '}
                                {currentTokenPairPrice.quoteCode}
                            </P>
                        </Col>
                    </Row>
                    {/* <MarketInput
                        className='mb-2'
                        name='price'
                        prefix='Precio'
                        value={buyFormik.values.price}
                        disabled
                        suffix={currentTokenPairPrice?.baseCode || ''}
                    /> */}
                    <MarketInput
                        className='mb-2'
                        name='target'
                        type='number'
                        value={buyFormik.values.target}
                        onChange={(value) =>
                            handleBuyChange({
                                price: buyFormik.values.price,
                                target: value,
                                amount: buyFormik.values.amount,
                                total: buyFormik.values.total,
                            })
                        }
                        onBlur={buyFormik.handleBlur}
                        prefix='Objetivo'
                        suffix={currentTokenPairPrice?.quoteCode || ''}
                    />
                    <MarketInput
                        className='mb-2'
                        name='amount'
                        prefix='Monto'
                        value={buyFormik.values.amount}
                        onChange={(value) =>
                            handleBuyChange({
                                price: buyFormik.values.price,
                                target: buyFormik.values.target,
                                amount: value,
                                total: buyFormik.values.total,
                            })
                        }
                        onBlur={buyFormik.handleBlur}
                        suffix={currentTokenPairPrice?.quoteCode || ''}
                    />
                    <Button
                        type='submit'
                        disabled={getBuyDisabled()}
                        style={{ width: '100%' }}
                        className='mb-2'
                    >
                        {`Comprar ${currentTokenPairPrice.baseCode}`}
                    </Button>
                    <MarketInput
                        name='total'
                        prefix='Obtienes'
                        type='number'
                        value={buyFormik.values.total}
                        disabled
                        suffix={currentTokenPairPrice?.baseCode || ''}
                    />
                </Form>
            </Col>
            <Col>
                <Form onSubmit={sellFormik.handleSubmit}>
                    <Row className='mb-2'>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Disponible
                            </P>
                        </Col>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'right',
                                }}
                            >
                                {dotAndComma(formatPrice(tokenPairBaseBalance))}{' '}
                                {currentTokenPairPrice.baseCode}
                            </P>
                        </Col>
                    </Row>
                    {/* <MarketInput
                        className='mb-2'
                        name='price'
                        prefix='Precio'
                        disabled
                        value={sellFormik.values.price}
                        suffix={currentTokenPairPrice?.baseCode || ''}
                    /> */}
                    <MarketInput
                        className='mb-2'
                        name='target'
                        prefix='Objetivo'
                        type='number'
                        value={sellFormik.values.target}
                        onChange={(value) =>
                            handleSellChange({
                                price: sellFormik.values.price,
                                target: value,
                                amount: sellFormik.values.amount,
                                total: sellFormik.values.total,
                            })
                        }
                        onBlur={sellFormik.handleBlur}
                        suffix={currentTokenPairPrice?.quoteCode || ''}
                    />
                    <MarketInput
                        className='mb-2'
                        name='amount'
                        prefix='Monto'
                        value={sellFormik.values.amount}
                        onChange={(value) =>
                            handleSellChange({
                                target: sellFormik.values.target,
                                price: sellFormik.values.price,
                                amount: value,
                                total: sellFormik.values.total,
                            })
                        }
                        onBlur={sellFormik.handleBlur}
                        suffix={currentTokenPairPrice?.baseCode || ''}
                    />
                    <Button
                        color='secondary'
                        type='submit'
                        disabled={getSellDisabled()}
                        style={{ width: '100%' }}
                        className='mb-2'
                    >
                        {`Vender ${currentTokenPairPrice.baseCode}`}
                    </Button>
                    <MarketInput
                        name='total'
                        prefix='Obtienes'
                        value={sellFormik.values.total}
                        disabled
                        suffix={currentTokenPairPrice?.quoteCode || ''}
                    />
                </Form>
            </Col>
        </Row>
    );
};
