import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { getBalanceExtended, getTokenPairs } from 'common/commonService';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { ORDER_SIDE } from '../types';
import { getBuyAndSellPrices } from 'utils';
import {
    cancelExchangeLimit,
    confirmExchangeQuote,
    getExchangeOrders,
} from '../quotationService';
import { Button, BuyModal, CircularProgressBar, H6, P } from '@common';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { quotationActions } from '../quotationSlice';
import { NavLink } from 'react-router-dom';
import type { CoinComponentValue } from 'common/helper';
import { differenceInSeconds } from 'date-fns';
import { formatNumberWithCommas } from '../../../../utils/string';
import { twoDecimals } from '../../../../utils/currency';

export interface ConfirmDetailState {
    coinFrom: CoinComponentValue;
    coinTo: CoinComponentValue;
    amountFrom: string;
    amountTo: string;
}

export const Confirm = () => {
    const dispatch = useAppDispatch();
    const { tokenPairPrices } = useAppSelector((state) => state.common);
    const { addedExchangeQuote } = useAppSelector((state) => state.quotation);

    const { modalOpen } = useAppSelector((state) => state.modal);

    useEffect(() => {
        dispatch(getTokenPairs());
    }, []);

    const getBuyingSellingTexts = () => {
        const foundTPP = tokenPairPrices?.find(
            (tp) => tp.symbol === addedExchangeQuote?.symbol,
        );
        if (!addedExchangeQuote || !tokenPairPrices || !foundTPP)
            return '0,00000000 = 0,00000000';
        const { amount, amount_in, orderSide, priceQuote } = addedExchangeQuote;
        let from = '';
        let to = '';
        const amountValue = getBuyAndSellPrices(foundTPP, amount, priceQuote);
        if (orderSide === ORDER_SIDE.BUY) {
            from = // if coinType is ARS or USDT, show 2 decimals, esle show 8 decimals
                foundTPP.quoteCode === 'ARS' || foundTPP.quoteCode === 'USDT'
                    ? `${formatNumberWithCommas(amount, amount_in, true)} ${
                          foundTPP.quoteCode
                      }`
                    : formatNumberWithCommas(amount, amount_in);
            to =
                foundTPP.baseCode === 'ARS' || foundTPP.baseCode === 'USDT'
                    ? `${formatNumberWithCommas(
                          amountValue.buy.toString(),
                          foundTPP.baseCode,
                          true,
                      )} ${foundTPP.baseCode}`
                    : formatNumberWithCommas(
                          amountValue.buy.toFixed(8),
                          foundTPP.baseCode,
                      );
        }
        if (orderSide === ORDER_SIDE.SELL) {
            from = // if coinType is ARS or USDT, show 2 decimals, esle show 8 decimals
                foundTPP.quoteCode === 'ARS' || foundTPP.quoteCode === 'USDT'
                    ? `${formatNumberWithCommas(amount, amount_in, true)} ${
                          foundTPP.baseCode
                      }`
                    : formatNumberWithCommas(amount, amount_in);
            to =
                foundTPP.baseCode === 'ARS' || foundTPP.baseCode === 'USDT'
                    ? `${formatNumberWithCommas(
                          amountValue.sell.toString(),
                          foundTPP.quoteCode,
                          true,
                      )} ${foundTPP.quoteCode}`
                    : formatNumberWithCommas(
                          amountValue.sell.toFixed(8),
                          foundTPP.quoteCode,
                      );
        }
        return `${from} = ${to}`;
    };

    const getRemainingSeconds = () => {
        if (!addedExchangeQuote) return 0;
        const { expirationAt, createdAt } = addedExchangeQuote;
        const dif = differenceInSeconds(
            new Date(expirationAt),
            new Date(createdAt),
        );

        const requestTime = differenceInSeconds(
            new Date(),
            new Date(createdAt),
        );

        // dif minus time passed
        const remainingSeconds = dif - requestTime;
        return remainingSeconds;
    };

    return (
        <BuyModal
            onClose={() => dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))}
            open={modalOpen === MODAL_OPEN.BUY_CONFIRM}
            colorTop
            hideClose
        >
            <div
                className='confirm-receipt-container'
                style={{
                    height: '83px',
                    borderRadius: '13px',
                    border: 'solid 5px #d7e1de',
                    backgroundColor: 'rgba(215, 225, 222, 0.39)',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px 20px 0px 20px',
                }}
            >
                <H6
                    style={{
                        marginBottom: '0px !important',
                    }}
                >
                    {getBuyingSellingTexts()}
                </H6>
            </div>
            <div style={{ width: '250px' }}>
                <P
                    color='info'
                    style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        textAlign: 'center',
                    }}
                >
                    Bisbull asegura el precio de compra actual por 60 segundos
                </P>
            </div>
            <div className='mt-3 mb-2'>
                <CircularProgressBar
                    seconds={
                        // if modal is open, start from 60 seconds
                        modalOpen === MODAL_OPEN.BUY_CONFIRM
                            ? getRemainingSeconds()
                            : 60
                    }
                    onTimeOut={() => {
                        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
                    }}
                />
            </div>
            <Button
                className='m-3'
                onClick={() => {
                    if (!addedExchangeQuote) return;
                    dispatch(
                        confirmExchangeQuote({
                            id: addedExchangeQuote.id,
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            dispatch(
                                modalActions.setModalOpen(
                                    MODAL_OPEN.BUY_RECEIPT,
                                ),
                            );
                            if (addedExchangeQuote.executeAfterConfirm) {
                                dispatch(
                                    cancelExchangeLimit({
                                        exchange:
                                            addedExchangeQuote.executeAfterConfirm,
                                    }),
                                )
                                    .unwrap()
                                    .then(() => {
                                        dispatch(getExchangeOrders());
                                        dispatch(getBalanceExtended());
                                    })
                                    .catch(() => {
                                        toast.error(
                                            'Hubo un error al ejecutar su orden (paso 2 de 2, cancelar orden de límite), vuelve a intentarlo',
                                        );
                                    });
                            } else {
                                dispatch(getExchangeOrders());
                                dispatch(getBalanceExtended());
                            }
                        })
                        .catch((err: any) => {
                            toast.error(err.message);
                            dispatch(
                                modalActions.setModalOpen(MODAL_OPEN.NONE),
                            );
                        });
                }}
            >
                {addedExchangeQuote?.orderSide === 'BUY' && 'Comprar'}
                {addedExchangeQuote?.orderSide === 'SELL' && 'Vender'}
            </Button>
            <P
                color='danger'
                style={{
                    textDecoration: 'underline',
                    marginBottom: '10px',
                }}
            >
                <NavLink
                    onClick={() => {
                        dispatch(
                            quotationActions.setBuyCoinValues({
                                from: '',
                                to: '',
                                fromValue: '',
                                toValue: '',
                            }),
                        );
                        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
                    }}
                    style={{ color: '#d43554' }}
                    to='#'
                >
                    Cancelar
                </NavLink>
            </P>
        </BuyModal>
    );
};
