import { Loader } from 'common';
import type { Dispatch, SetStateAction } from 'react';
import { Suspense, useEffect, useState } from 'react';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes as ReactRouterDomRoutes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { Landing } from 'screens/landing';
import { getUserType } from 'screens/user/profile/profileService';
import { USER_TYPE } from 'screens/user/profile/types';
import { ACCESS_TOKEN, AUTHENTICATED } from 'utils/axios/index';
import { AppLayout } from './AppLayout';
import { ROUTE_PATH } from './routes/const';
import { operatorRoutes } from './routes/operatorRoutes';
import { sessionRoutes } from './routes/sessionRoutes';
import { userRoutes } from './routes/userRoutes';

const RefreshHelper = ({
    setFirstRender,
}: {
    setFirstRender: Dispatch<SetStateAction<boolean>>;
}) => {
    const dispatch = useAppDispatch();
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    useEffect(() => {
        if (accessToken) dispatch(getUserType());
        setFirstRender(false);
    }, []);

    return null;
};

export const Routes = () => {
    const [firstRender, setFirstRender] = useState(true);
    const { requestStates } = useAppSelector((state) => state.session);
    const userType = useAppSelector((state) => state.profile.userType);
    const authenticated = localStorage.getItem(AUTHENTICATED) === 'true';
    const refreshState = requestStates.refresh;
    const getAuthenticatedRoutes = () => {
        if (userType === USER_TYPE.USER)
            return { routes: userRoutes, keyPrefix: 'user-route-' };
        if (userType === USER_TYPE.OPERATOR)
            return { routes: operatorRoutes, keyPrefix: 'operator-route-' };
        else {
            return { routes: null, keyPrefix: '' };
        }
    };
    const authenticatedRoutes = getAuthenticatedRoutes();
    console.log('routes', { authenticatedRoutes });

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader show />}>
                <RefreshHelper setFirstRender={setFirstRender} />
                <Loader
                    show={
                        refreshState === REQUEST_STATE.LOADING ||
                        firstRender ||
                        (!authenticatedRoutes.routes && authenticated)
                    }
                />
                <ReactRouterDomRoutes>
                    <Route
                        path='/'
                        element={
                            authenticated ? (
                                <Navigate to={ROUTE_PATH.DASHBOARD} />
                            ) : (
                                <Landing />
                            )
                        }
                    />
                    {authenticated && (
                        <Route
                            path='/*'
                            element={
                                <ReactRouterDomRoutes>
                                    {authenticatedRoutes.routes?.map(
                                        ({ path, Component }, i) => (
                                            <Route
                                                element={
                                                    <AppLayout showHelpModal />
                                                }
                                                key={
                                                    authenticatedRoutes.keyPrefix +
                                                    i
                                                }
                                            >
                                                <Route
                                                    path={path}
                                                    element={Component}
                                                />
                                            </Route>
                                        ),
                                    )}
                                    {!authenticatedRoutes.routes ||
                                        (authenticatedRoutes.routes.length >
                                            0 && (
                                            <Route
                                                path='*'
                                                element={<Navigate to='/' />}
                                            />
                                        ))}
                                </ReactRouterDomRoutes>
                            }
                        />
                    )}
                    {!authenticated &&
                        sessionRoutes.map(({ path, Component }, i) => (
                            <Route
                                key={`session-route-${i}`}
                                path={path}
                                element={Component}
                            />
                        ))}
                    <Route path='*' element={<Navigate to='/' />} />
                </ReactRouterDomRoutes>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                />
            </Suspense>
        </BrowserRouter>
    );
};
