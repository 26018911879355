/**
 * ALL_URL
 * @description cointains all url to be used
 */
export const ALL_URL = {
    /* session */
    REGISTER: 'auth/users/',
    REGISTER_SECOND_STEP: 'auth/users/activation/',
    REGISTER_ACTIVATE: 'auth/users/activation/',
    LOGIN: 'auth/jwt/create/',
    GET_TOTP: 'auth/totp/create/',
    LOGIN_TOTP: 'auth/totp/login/',
    REFRESH: 'auth/jwt/refresh/',
    RECOVER_PASSWORD: 'auth/users/reset_password/',
    RECOVER_PASSWORD_CONFIRM: 'auth/users/reset_password_confirm/',
    USER_PHOTO_FACE: 'auth/user_photo_face/',
    USER_PHOTO_ID: 'auth/user_photo_id/',
    USER_PHOTO_SELFIE: 'auth/user_photo_selfie/',
    /* profile */
    USER_TYPE: 'api/v1/user_type/',
    PERSONAL_DATA: 'auth/users/me/',
    SET_PASSWORD: 'auth/users/set_password/',
    SET_PERSONAL_DATA: 'auth/users/me/',
    GET_AVATAR: '/auth/avatar_url/',
    SET_AVATAR: '/auth/avatar/',
    PAYMENT_METHOD: 'auth/user_payment_method/',
    /* common */
    GET_COINS: 'api/v1/currency/',
    GET_COUNTRIES: 'api/v1/country/',
    GET_TOKEN_PAIRS: 'api/v1/tokenpair/',
    GET_TOKEN_PAIR_PRICES: 'api/v1/tokenpairprice/',
    BALANCE: 'api/v1/balanceuser/',
    GET_TOKEN_PAIR_PRICES_24_HR: 'api/v1/tokenpairprice24hr/',
    BALANCE_EXTEDED: 'api/v1/balanceuserextended/',
    /* exchanges */
    EXCHANGE_QUOTE: 'api/v1/walletexchante_quote/',
    EXCHANGE_QUOTE_CONFIRM: 'api/v1/walletexchante_quote_confirmation/',
    EXCHANGE_LIMIT: 'api/v1/walletexchante_limit/',
    CANCEL_EXCHANGE_LIMIT: 'api/v1/walletexchante_limit_cancel/',
    EXECUTE_EXCHANGE_LIMIT: 'api/v1/walletexchante_limit_execute/',
    EXCHANGE_LIMIT_REACTIVATE: 'api/v1/walletexchante_limit_reactivate/',
    EXCHANGE_ORDER: 'api/v1/walletexchante_order/',
    /* operator */
    OPERATOR_DAEMON_SWITCH: 'api/v1/daemon_switch/',
    OPERATOR_EXCHANGE_ORDER: 'api/v1/operator_walletexchante_order/',
    OPERATOR_USERS: 'api/v1/operator_user/',
    OPERATOR_EXCHANGE_ADVICE: 'api/v1/operator_walletexchante_advice/',
    OPERATOR_EXCHANGE_ADVICE_DONE:
        'api/v1/operator_walletexchante_advice_done/',
    OPERATOR_EXCHANGE_WITHDRAWAL: 'api/v1/operator_walletexchante_withdrawal/',
    OPERATOR_EXCHANGE_DEPOSIT: 'api/v1/operator_walletexchante_deposit/',
    OPERATOR_EXCHANGE_LIMIT_SUSPEND:
        'api/v1/operator_walletexchante_limit_cancel/',
    OPERATOR_EXCHANGE_LIMIT_REACTIVATE:
        'api/v1/operator_walletexchante_limit_reactivate/',
    OPERATOR_EXCHANGE_WITHDRAWAL_DONE:
        'api/v1/operator_walletexchante_withdrawal_execute/',
    OPERATOR_EXCHANGE_WITHDRAWAL_CANCEL:
        'api/v1/operator_walletexchante_withdrawal_cancel/',
    OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO:
        'api/v1/operator_walletexchante_withdrawalcrypto/',
    OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO_DONE:
        'api/v1/operator_walletexchante_withdrawalcrypto_execute/',
    OPERATOR_EXCHANGE_WITHDRAWAL_CRYPTO_CANCEL:
        'api/v1/operator_walletexchante_withdrawalcrypto_cancel/',
    /* wallet */
    EXCHANGE_WITHDRAWAL: 'api/v1/walletexchante_withdrawal/',
    EXCHANGE_DEPOSIT: 'api/v1/walletexchante_deposit/',
    DEPOSIT_ARS: '',
    USER_CRYPTO_ADDRESS: 'api/v1/user_cryptoaddress/',
    GET_CRYPTO_NETWORKS: 'api/v1/cryptonetwork/',
    GET_CURRENCY_CRYPTO_NETWORK: 'api/v1/currency_cryptonetwork/',
    EXCHANGE_WITHDRAWAL_CRYPTO: 'api/v1/walletexchante_withdrawalcrypto/',
};
