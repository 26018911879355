import { createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'redux/store';
import { ALL_URL } from 'redux/url';
import { axiosInstance } from 'utils/axios/index';

type GetCountriesResponse = {
    id: number;
    name: string;
    code: string;
    active: boolean;
}[];

export const getCountries = createAsyncThunk<GetCountriesResponse>(
    'GET_COUNTRIES',
    async (params, { rejectWithValue, getState }) => {
        try {
            const { common } = getState() as RootState;
            if (common.countries) return common.countries;
            const { data } = await axiosInstance.get(ALL_URL.GET_COUNTRIES);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type GetCoinsResponse = {
    id: string;
    name: string;
    token_cod: string;
    is_crypto: boolean;
    active: boolean;
}[];

export const getCoins = createAsyncThunk<GetCoinsResponse>(
    'GET_COINS',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.GET_COINS);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type GetTokenPairsResponse = {
    id: number;
    base_cod: string;
    quote_cod: string;
    is_crypto: boolean;
    symbol: string;
    symbol_pretty: string;
}[];

export const getTokenPairs = createAsyncThunk<GetTokenPairsResponse>(
    'GET_TOKEN_PAIRS',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.GET_TOKEN_PAIRS);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface GetTokenPairPriceResponse {
    tokenpair_id: number;
    base_cod: string;
    base_balance: string;
    quote_cod: string;
    quote_balance: string;
    symbol: string;
    symbol_pretty: string;
    is_crypto: boolean;
    net_price: string;
    total_price: string;
    fiat_spread_perc: string;
    fiat_spread_amount: string;
    fee_perc: string;
    fee_amount: string;
}

interface GetTokenPairPriceParams {
    id: string;
}

export const getTokenPairPrice = createAsyncThunk<
    GetTokenPairPriceResponse,
    GetTokenPairPriceParams
>('GET_TOKEN_PAIR_PRICE', async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.get(
            ALL_URL.GET_TOKEN_PAIR_PRICES + params.id + '/',
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

export type GetTokenPairPricesResponse = {
    tokenpair_id: number;
    base_cod: string;
    base_balance: string;
    quote_cod: string;
    quote_balance: string;
    symbol: string;
    symbol_pretty: string;
    is_crypto: boolean;
    net_price: string;
    total_price: string;
    fiat_spread_perc: string;
    fiat_spread_amount: string;
    fee_perc: string;
    fee_amount: string;
}[];

export const getTokenPairPrices = createAsyncThunk<GetTokenPairPricesResponse>(
    'GET_TOKEN_PAIR_PRICES',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                ALL_URL.GET_TOKEN_PAIR_PRICES,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface BalanceResponseItem {
    currency_id: number;
    token_cod: string;
    amount: string;
}

type GetBalanceResponse = BalanceResponseItem[];

export const getBalance = createAsyncThunk<GetBalanceResponse>(
    'GET_BALANCE',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.BALANCE);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type SetBalanceResponse = BalanceResponseItem;

interface SetBalanceParams {
    currency_id: number;
    amount: string;
}

export const setBalance = createAsyncThunk<
    SetBalanceResponse,
    SetBalanceParams
>('SET_BALANCE', async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.put(
            ALL_URL.BALANCE + params.currency_id + '/',
            { amount: params.amount },
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

type AddBalanceResponse = BalanceResponseItem;

interface AddBalanceParams {
    currency_id: number;
    amount_add: string;
}

export const addBalance = createAsyncThunk<
    AddBalanceResponse,
    AddBalanceParams
>('ADD_BALANCE', async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(
            ALL_URL.BALANCE + params.currency_id + '/',
            { amount_add: params.amount_add },
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

type GetTokenPairPrices24HRResponse = {
    tokenpair_id: number;
    base_id: number;
    quote_id: number;
    symbol: string;
    priceChange: string;
    priceChangePercent: string;
    weightedAvgPrice: string;
    prevClosePrice: string;
    lastPrice: string;
}[];

export const getTokenPairPrices24hr =
    createAsyncThunk<GetTokenPairPrices24HRResponse>(
        'GET_TOKEN_PAIR_PRICES_24_HR',
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.GET_TOKEN_PAIR_PRICES_24_HR,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );

type GetBalanceExtended = {
    currency_id: number;
    token_cod: string;
    amount: string;
    amount_ARS: string;
    amount_USD: string;
    balance_BTC: string;
    balance_ETH: string;
}[];

export const getBalanceExtended = createAsyncThunk<GetBalanceExtended>(
    'GET_BALANCE_EXTENDED',
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.BALANCE_EXTEDED);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
