import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_URL } from 'redux/url';
import { axiosInstance } from 'utils/axios/index';
import { subHours } from 'date-fns';
import axios from 'axios';
import { getUSDTBaseCodeSymbols } from 'utils/currency';
import type { RootState } from 'redux/store';
import type { ORDER_SIDE } from './types';

enum QUOTATION_REQUEST {
    GET_EXCHANGE_QUOTE = 'GET_EXCHANGE_QUOTE',
    ADD_EXCHANGE_QUOTE = 'ADD_EXCHANGE_QUOTE',
    CONFIRM_EXCHANGE_QUOTE = 'CONFIRM_EXCHANGE_QUOTE',
    GET_EXCHANGE_LIMIT = 'GET_EXCHANGE_LIMIT',
    ADD_EXCHANGE_LIMIT = 'ADD_EXCHANGE_LIMIT',
    CANCEL_EXCHANGE_LIMIT = 'CANCEL_EXCHANGE_LIMIT',
    EXECUTE_EXCHANGE_LIMIT = 'EXECUTE_EXCHANGE_LIMIT',
    GET_EXCHANGE_ORDER = 'GET_EXCHANGE_ORDER',
    GET_COIN_VARIATION = 'GET_COIN_VARIATION',
    SET_EXCHANGE_LIMIT_ACTIVATE = 'SET_EXCHANGE_LIMIT_ACTIVATE',
}

export interface ExchangeQuoteResponseItem {
    id: number;
    tokenpair_id: number;
    order_side: ORDER_SIDE;
    symbol: string;
    amount: string;
    amount_in: string;
    price_quote: string;
    fee_perc: string;
    fee: string;
    fiat_spread_perc: string;
    fiat_spread: string;
    created_at: string;
    expiration_at: string;
    confirmed: boolean;
    change_state_by: number;
    change_state_at: string;
}

type GetQuotationResponse = ExchangeQuoteResponseItem[];

export const getQuotation = createAsyncThunk<GetQuotationResponse>(
    QUOTATION_REQUEST.GET_EXCHANGE_QUOTE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.EXCHANGE_QUOTE);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type AddExchangeQuoteResponse = ExchangeQuoteResponseItem & {
    executeAfterConfirm: number | null;
};

interface AddExchangeQuoteParams {
    tokenpair_id: number;
    amount: string;
    order_side: ORDER_SIDE;
    symbol: string;
    amount_in: string;
    executeAfterConfirm: number | null;
}

export const addExchangeQuote = createAsyncThunk<
    AddExchangeQuoteResponse,
    AddExchangeQuoteParams
>(QUOTATION_REQUEST.ADD_EXCHANGE_QUOTE, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(ALL_URL.EXCHANGE_QUOTE, {
            tokenpair_id: params.tokenpair_id,
            amount: params.amount,
            order_side: params.order_side,
            symbol: params.symbol,
            amount_in: params.amount_in,
        });
        return { ...data, executeAfterConfirm: params.executeAfterConfirm };
    } catch (error: any) {
        const status: number = error.response.status;
        const errorCode = parseInt(error?.response?.data?.error);
        const message = getAddExchangeConfirmErrorMessage(errorCode);
        return rejectWithValue({ status, message });
    }
});

interface ConfirmExchangeQuoteParams {
    id: number;
}

type ConfirmExchangeQuoteResponse = ExchangeQuoteResponseItem;

const getAddExchangeConfirmErrorMessage = (n: number): string => {
    switch (n) {
        case 1:
            /* Operator Base Balance is not enough. It's needed 10.00.
            Please contact the operator or try again later! */
            return 'La operación no se pudo realizar, si el problema persiste contacte el administrador.';
        case 2:
            /* User Quote Balance is not enough. It's needed 197816110144.00
            and there's 91100.00" */
            return 'El saldo en su cuenta no permite realizar la operación. Si el problema persiste contacte al Administrador.';
        case 3:
            /* Quote expired */
            return 'Tu operación ha expirado. Inténtalo nuevamente.';
        default:
            return '';
    }
};

export const confirmExchangeQuote = createAsyncThunk<
    ConfirmExchangeQuoteResponse,
    ConfirmExchangeQuoteParams
>(
    QUOTATION_REQUEST.CONFIRM_EXCHANGE_QUOTE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.EXCHANGE_QUOTE_CONFIRM + params.id + '/',
                {
                    id: params.id,
                },
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const errorCode = parseInt(error?.response?.data?.error);
            const message = getAddExchangeConfirmErrorMessage(errorCode);
            return rejectWithValue({ status, message });
        }
    },
);

interface ExchangeLimitResponseItem {
    id: number;
    tokenpair_id: number;
    order_side: ORDER_SIDE;
    symbol: string;
    amount: string;
    amount_in: string;
    price_target: string;
    fee_perc: string;
    fee: string;
    fiat_spread_perc: string;
    fiat_spread: string;
    created_at: string;
    updated_at: string;
    change_state_by: number;
    active: boolean;
    change_state_at: string;
}

type GetExchangeLimitResponse = ExchangeLimitResponseItem[];

export const getExchangeLimit = createAsyncThunk<GetExchangeLimitResponse>(
    QUOTATION_REQUEST.GET_EXCHANGE_LIMIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.EXCHANGE_LIMIT);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

type AddExchangeLimitResponse = ExchangeLimitResponseItem;

interface AddExchangeLimitParams {
    order_side: ORDER_SIDE;
    tokenpair_id: number;
    symbol: string;
    amount: string;
    amount_in: string;
    price_target: string;
}

export const addExchangeLimit = createAsyncThunk<
    AddExchangeLimitResponse,
    AddExchangeLimitParams
>(QUOTATION_REQUEST.ADD_EXCHANGE_LIMIT, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(ALL_URL.EXCHANGE_LIMIT, {
            order_side: params.order_side,
            tokenpair_id: params.tokenpair_id,
            symbol: params.symbol,
            amount: params.amount,
            amount_in: params.amount_in,
            price_target: params.price_target,
        });
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const errorCode = parseInt(error?.response?.data?.error);
        const message = getAddExchangeConfirmErrorMessage(errorCode);
        return rejectWithValue({ status, message });
    }
});

interface CancelExchangeLimitParams {
    exchange: number;
}

export const cancelExchangeLimit = createAsyncThunk<
    Record<string, never>,
    CancelExchangeLimitParams
>(
    QUOTATION_REQUEST.CANCEL_EXCHANGE_LIMIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.CANCEL_EXCHANGE_LIMIT + params.exchange + '/',
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const errorCode = parseInt(error?.response?.data?.error);
            const message = getAddExchangeConfirmErrorMessage(errorCode);
            return rejectWithValue({ status, message });
        }
    },
);

interface ExecuteExchangeLimitParams {
    exchange: number;
}

export const executeExchangeLimit = createAsyncThunk<
    Record<string, never>,
    ExecuteExchangeLimitParams
>(
    QUOTATION_REQUEST.EXECUTE_EXCHANGE_LIMIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.EXECUTE_EXCHANGE_LIMIT + params.exchange + '/',
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const errorCode = parseInt(error?.response?.data?.error);
            const message = getAddExchangeConfirmErrorMessage(errorCode);
            return rejectWithValue({ status, message });
        }
    },
);

export interface ExchangeOrderResponseItem {
    id: number;
    tokenpair_id: number;
    order_type: string; // order_type: 'EXCHANGE MARKET';
    order_side: ORDER_SIDE;
    symbol: string;
    amount: string;
    amount_in: string;
    net_price: string;
    price_target: string;
    fee_perc: string;
    fee: string;
    fiat_spread_perc: string;
    fiat_spread: string;
    exchange: number;
    created_at: string;
    updated_at: string;
    active: boolean;
    executed: boolean;
    change_state_by: number;
    change_state_at: string;
    converted_to_quote: number;
}

type GetExchangeOrderResponse = ExchangeOrderResponseItem[];

export const getExchangeOrders = createAsyncThunk<GetExchangeOrderResponse>(
    QUOTATION_REQUEST.GET_EXCHANGE_ORDER,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.EXCHANGE_ORDER);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export type SymbolVariationObj = Record<string, string[]>;

type GetCoinVariationResponse = SymbolVariationObj;

const _symbolToFullPath = (symbol: string): string => {
    const today = new Date();
    return `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=4h&startTime=${subHours(
        today,
        40,
    ).getTime()}&endTime=${today.getTime()}`;
};

export const getCoinVaration = createAsyncThunk<GetCoinVariationResponse>(
    QUOTATION_REQUEST.GET_COIN_VARIATION,
    async (params, { getState, rejectWithValue }) => {
        try {
            const tokenPairs = (getState() as RootState).common.tokenPairs;
            const symbolsToRequest = getUSDTBaseCodeSymbols(tokenPairs);
            const paths = symbolsToRequest.map(_symbolToFullPath);
            return axios.all(paths.map((path) => axios.get(path))).then(
                axios.spread((...responses) => {
                    const result: SymbolVariationObj = {};
                    responses.forEach((variationResponse, symbolIndex) => {
                        const symbol = symbolsToRequest[symbolIndex];
                        const variations: string[] = variationResponse.data.map(
                            (variationArrayItem: string) => {
                                const closePrice =
                                    variationArrayItem[4] as string;
                                return closePrice;
                            },
                        );
                        result[symbol] = variations;
                    });
                    return result;
                }),
            );
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface ExchangeLimitReactivateParams {
    id: number;
}
type ExchangeLimitReactivateResponse = Record<string, never>;

export const reactivateExchangeLimit = createAsyncThunk<
    ExchangeLimitReactivateResponse,
    ExchangeLimitReactivateParams
>(
    QUOTATION_REQUEST.SET_EXCHANGE_LIMIT_ACTIVATE,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.EXCHANGE_LIMIT_REACTIVATE + params.id + '/',
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
