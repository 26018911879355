import { Button, FormInput, Modal, P, TextDropdown } from 'common';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
    getPaymentMethod,
    personalData,
} from 'screens/user/profile/profileService';
import { COIN_TYPES } from 'utils/const';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { getBalanceExtended } from '../../../../common/commonService';
import type { PaymentMethod } from 'screens/user/profile/profileService';
import { loginTOTP } from 'screens/session/sessionService';
import './WalletCard.scss';
import { ThemeContext } from 'providers/ThemeProvider';
import {
    addExchangeWithdrawal,
    getCryptoNetworks,
    getCurrencyCryptoNetwork,
    addExchangeWithdrawalCrypto,
} from '../walletService';
import { REQUEST_STATE } from 'redux/types';

interface WithdrawalModalProps {
    modalData?: string;
}

const getARSValidationSchema = (paymentMethod: PaymentMethod | null) =>
    Yup.object().shape({
        amount: Yup.number()
            .test(
                'has-payment-method',
                'No tiene medios de pago asociados',
                () => {
                    return !!paymentMethod;
                },
            )
            .required('Este campo es requerido')
            .moreThan(0, 'El monto debe ser mayor a 0'),
        pinCode: Yup.string()
            .length(6, 'Este campo es requerido')
            .required('Este campo es requerido'),
    });

const getCryptoValidationSchema = () =>
    Yup.object().shape({
        amount: Yup.number()
            .required('Este campo es requerido')
            .moreThan(0, 'El monto debe ser mayor a 0'),
        pinCode: Yup.string()
            .length(6, 'Este campo es requerido')
            .required('Este campo es requerido'),
        address: Yup.string().required('Este campo es requerido'),
    });

export const WithdrawalModal = (props: WithdrawalModalProps) => {
    const dispatch = useAppDispatch();
    const { modalOpen } = useAppSelector((state) => state.modal);
    const coins = useAppSelector((state) => state.common.coins);
    const { userData, paymentMethod } = useAppSelector(
        (state) => state.profile,
    );
    const theme = useContext(ThemeContext);
    const [colorMode, setColorMode] = useState('');
    const { cryptoNetworks, currencyCryptoNetwork } = useAppSelector(
        (state) => state.wallet,
    );
    const {
        getCryptoNetworks: cryptoNetworksReq,
        addExchangeWithdrawalCrypto: addExchangeWithdrawalCryptoReq,
        addExchangeWithdrawal: addExchangeWithdrawalReq,
    } = useAppSelector((state) => state.wallet.requestStates);
    const loginTOTPRequestState = useAppSelector(
        (state) => state.session.requestStates.loginTOTP,
    );

    const loading =
        cryptoNetworksReq === REQUEST_STATE.LOADING ||
        addExchangeWithdrawalCryptoReq === REQUEST_STATE.LOADING ||
        addExchangeWithdrawalReq === REQUEST_STATE.LOADING ||
        loginTOTPRequestState === REQUEST_STATE.LOADING;

    useEffect(() => {
        if (
            modalOpen === MODAL_OPEN.WITHDRAWAL_MODAL &&
            props.modalData !== COIN_TYPES.ARS
        ) {
            dispatch(getCryptoNetworks());
            dispatch(getCurrencyCryptoNetwork());
        }
    }, [modalOpen]);

    useEffect(() => {
        if (theme.mix_background_layout === 'dark-only') {
            setColorMode(' dark');
        } else {
            setColorMode('');
        }
    }, [theme]);

    useEffect(() => {
        dispatch(getPaymentMethod());
        dispatch(personalData());
    }, []);

    const ARSFormik = useFormik({
        initialValues: { amount: '', pinCode: '' },
        validationSchema: getARSValidationSchema(paymentMethod),
        onSubmit: (values) => {
            const foundARS = coins?.find((c) => c.tokenCode === COIN_TYPES.ARS);
            if (!coins || !foundARS) return;

            dispatch(
                loginTOTP({
                    pinCode: ARSFormik.values.pinCode,
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(
                        addExchangeWithdrawal({
                            amount: values.amount,
                            currency_id: parseInt(foundARS.id),
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            toast.success(
                                'Su pedido de extracción se ha realizado correctamente.',
                            );
                            dispatch(getBalanceExtended());
                            closeModal();
                        })
                        .catch(() => {
                            toast.error(
                                'Hubo un error al extraer, intentelo nuevamente más tarde.',
                            );
                        });
                })
                .catch(() => {
                    toast.error('El código de seguridad es incorrecto.');
                });
        },
    });

    const CryptoFormik = useFormik({
        initialValues: { network: '', address: '', amount: '', pinCode: '' },
        validationSchema: getCryptoValidationSchema(),
        onSubmit: (values) => {
            const foundCrypto = coins?.find(
                (c) => c.tokenCode === props.modalData,
            );

            if (!coins || !foundCrypto) return;

            dispatch(
                loginTOTP({
                    pinCode: CryptoFormik.values.pinCode,
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(
                        addExchangeWithdrawalCrypto({
                            currency_id: parseInt(foundCrypto.id),
                            address: values.address,
                            cryptonetwork_id: parseInt(values.network),
                            amount: parseFloat(values.amount),
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            toast.success(
                                'Su pedido de extracción se ha realizado correctamente.',
                            );
                            dispatch(getBalanceExtended());
                            closeModal();
                        })
                        .catch(() => {
                            toast.error(
                                'Hubo un error al extraer, intentelo nuevamente más tarde.',
                            );
                        });
                })
                .catch(() => {
                    toast.error('El código de seguridad es incorrecto.');
                });
        },
    });

    const closeModal = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
        ARSFormik.resetForm();
        CryptoFormik.resetForm();
    };

    const findNetworkFromSelectedCoin = () => {
        // get coins and match with modalData
        const foundCoin = coins?.find((c) => c.tokenCode === props.modalData);
        if (!coins || !foundCoin) return null;

        // match foundCoin with currencyId
        const foundCurrencyCryptoNetwork = currencyCryptoNetwork?.find(
            (c) => c.currencyId === parseInt(foundCoin.id),
        );

        // match foundCurrencyCryptoNetwork with cryptoNetworks
        const foundCryptoNetwork = cryptoNetworks?.find(
            (c) => c.id === foundCurrencyCryptoNetwork?.cryptonetworkId,
        );

        // return id and text for select
        return {
            id: foundCryptoNetwork?.id.toString(),
            text: foundCryptoNetwork?.name,
        };
    };

    const networkFounded = findNetworkFromSelectedCoin();

    const dropdownOptions = networkFounded
        ? [
              {
                  id: networkFounded.id || '',
                  text: networkFounded.text || '',
              },
          ]
        : [];

    const renderARSContent = () => {
        return (
            <Col className='p-5'>
                <Row>
                    <Col>
                        <Row>
                            <P style={{ fontWeight: 'bold', marginRight: 10 }}>
                                Nombre completo:
                            </P>
                            <P>{`${userData?.name} ${userData?.surname}`}</P>
                        </Row>
                        <Row>
                            <P style={{ fontWeight: 'bold', marginRight: 10 }}>
                                CBU:
                            </P>
                            <P>{paymentMethod?.cbu}</P>
                        </Row>
                        <Row>
                            <P style={{ fontWeight: 'bold', marginRight: 10 }}>
                                Alias:
                            </P>
                            <P>{paymentMethod?.alias}</P>
                        </Row>
                    </Col>
                </Row>
                <Form onSubmit={ARSFormik.handleSubmit}>
                    <Row className='mt-4'>
                        <FormInput
                            label='Monto'
                            name='amount'
                            type='number'
                            onChange={() => null}
                            onFormikChange={ARSFormik.handleChange}
                            value={ARSFormik.values.amount}
                            error={ARSFormik.errors.amount}
                        />
                        <FormInput
                            label='Ingresá el código de seguridad (2FA) generado'
                            name='pinCode'
                            type='pin-password'
                            value={ARSFormik.values.pinCode}
                            onChange={() => null}
                            onFormikChange={ARSFormik.handleChange}
                            error={ARSFormik.errors.pinCode}
                        />
                    </Row>
                    <Row className='mt-4'>
                        <Button
                            type='submit'
                            disabled={!ARSFormik.isValid}
                            loading={loading}
                        >
                            Aceptar
                        </Button>
                    </Row>
                </Form>
            </Col>
        );
    };

    const renderCryptoContent = () => {
        return (
            <Col className='p-5'>
                <Form onSubmit={CryptoFormik.handleSubmit}>
                    <Row>
                        <Label>Red</Label>
                    </Row>
                    <Row>
                        <TextDropdown
                            loading={loading}
                            noDataText='No data'
                            value={CryptoFormik.values.network}
                            options={dropdownOptions}
                            onChange={(e) => {
                                CryptoFormik.setFieldValue('network', e.id);
                                return;
                            }}
                            backgroundColor
                        />
                    </Row>
                    <Row className='mt-4'>
                        <FormInput
                            label='Dirección'
                            name='address'
                            type='text'
                            onChange={() => null}
                            onFormikChange={CryptoFormik.handleChange}
                            value={CryptoFormik.values.address}
                            error={CryptoFormik.errors.address}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label='Monto'
                            name='amount'
                            type='number'
                            onChange={() => null}
                            onFormikChange={CryptoFormik.handleChange}
                            value={CryptoFormik.values.amount}
                            error={CryptoFormik.errors.amount}
                        />
                    </Row>
                    <Row>
                        <FormInput
                            label='Ingresá el código de seguridad (2FA) generado'
                            name='pinCode'
                            type='pin-password'
                            value={CryptoFormik.values.pinCode}
                            onChange={() => null}
                            onFormikChange={CryptoFormik.handleChange}
                            error={CryptoFormik.errors.pinCode}
                        />
                    </Row>
                    <Row className='mt-4'>
                        <Button
                            type='submit'
                            disabled={!CryptoFormik.isValid}
                            loading={loading}
                        >
                            Aceptar
                        </Button>
                    </Row>
                </Form>
            </Col>
        );
    };

    return (
        <Modal
            className={`wallet-modal-open ${colorMode}`}
            title={`Extraer ${props.modalData}`}
            open={modalOpen === MODAL_OPEN.WITHDRAWAL_MODAL}
            onClose={() => {
                closeModal();
            }}
            noPadding
        >
            {props.modalData === COIN_TYPES.ARS
                ? renderARSContent()
                : renderCryptoContent()}
        </Modal>
    );
};
