import { getUserType } from 'screens/user/profile/profileService';
import {
    ACCESS_TOKEN,
    AUTHENTICATED,
    axiosInstance,
    FIRST_TIME_2FA,
    REFRESH_TOKEN,
} from '../../utils/axios/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_URL } from 'redux/url';
import axios from 'axios';
import type { RootState } from 'redux/store';

enum SESSION_REQUEST {
    LOGIN = 'LOGIN',
    GET_TOTP = 'GET_TOTP',
    LOGIN_TOTP = 'LOGIN_TOTP',
    REFRESH = 'REFRESH',
    REGISTER = 'REGISTER',
    REGISTER_ACTIVATE = 'REGISTER_ACTIVATE',
    RECOVER_PASSWORD = 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_CONFIRM = 'RECOVER_PASSWORD_CONFIRM',
}

export interface LoginResponse {
    refresh: string;
    access: string;
}

interface UserLoginParams {
    email: string;
    password: string;
}

export const login = createAsyncThunk<LoginResponse, UserLoginParams>(
    SESSION_REQUEST.LOGIN,
    async (params, { dispatch, rejectWithValue }) => {
        try {
            // not using axios instance to avoid interceptors
            const { data } = await axiosInstance.post(ALL_URL.LOGIN, {
                email: params.email,
                password: params.password,
            });
            localStorage.setItem(ACCESS_TOKEN, data.access);
            localStorage.setItem(REFRESH_TOKEN, data.refresh);
            const pref = localStorage.getItem('theme_preference');
            pref === 'light-only'
                ? (localStorage.setItem('theme_preference', 'light-only'),
                  localStorage.setItem('mix_background_layout', 'light-only'))
                : (localStorage.setItem('theme_preference', 'dark-only'),
                  localStorage.setItem('mix_background_layout', 'dark-only'));

            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export type GetTOTPResponse = string;

export const getTOTP = createAsyncThunk<GetTOTPResponse>(
    SESSION_REQUEST.GET_TOTP,
    async (params, { getState, rejectWithValue }) => {
        try {
            const access =
                (getState() as RootState).session.tempAccessToken ||
                localStorage.getItem(ACCESS_TOKEN);
            const { data } = await axios.get(ALL_URL.GET_TOTP, {
                headers: {
                    Authorization: `JWT ${access}`,
                },
                baseURL: process.env.REACT_APP_PUBLIC_URL,
            });
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface LoginTOTPParams {
    pinCode: string;
}

export const loginTOTP = createAsyncThunk<
    Record<string, never>,
    LoginTOTPParams
>(
    SESSION_REQUEST.LOGIN_TOTP,
    async (params, { getState, dispatch, rejectWithValue }) => {
        const access =
            (getState() as RootState).session.tempAccessToken ||
            localStorage.getItem(ACCESS_TOKEN);
        const refresh =
            (getState() as RootState).session.tempRefreshToken ||
            localStorage.getItem(REFRESH_TOKEN);
        if (!access || !refresh) throw new Error();

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_PUBLIC_URL}/${ALL_URL.LOGIN_TOTP}${params.pinCode}/`,
            headers: {
                Authorization: `JWT ${access}`,
                'Content-Type': 'application/json',
            },
        };

        try {
            // not using axios instance to avoid interceptors
            const { data } = await axios(config);

            localStorage.setItem(ACCESS_TOKEN, access);
            localStorage.setItem(REFRESH_TOKEN, refresh);

            localStorage.setItem(FIRST_TIME_2FA, 'false');
            localStorage.setItem(AUTHENTICATED, 'true');
            dispatch(getUserType());
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface RefreshResponse {
    access: string;
    refresh: string;
}

interface RefreshParams {
    refresh: string;
}

export const refreshToken = createAsyncThunk<RefreshResponse, RefreshParams>(
    SESSION_REQUEST.REFRESH,
    async (params, { rejectWithValue }) => {
        try {
            const refresh = localStorage.getItem(REFRESH_TOKEN);
            const { data } = await axiosInstance.post(ALL_URL.REFRESH, {
                refresh,
            });
            localStorage.setItem(ACCESS_TOKEN, data.access);
            localStorage.setItem(REFRESH_TOKEN, data.refresh);
            localStorage.setItem(AUTHENTICATED, 'true');
            return data;
        } catch (error: any) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(AUTHENTICATED);
            // window.location.href = '/';
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface RegisterResponse {
    nombre: string;
    apellido: string;
    cuit: string;
    comision: string;
    email: string;
    id: number;
}

interface UserRegisterParams {
    name: string;
    lastname: string;
    cuit: string;
    email: string;
    phone_country_cod: string;
    phone_number: string;
    password: string;
    re_password: string;
    country: string;
}

export const register = createAsyncThunk<RegisterResponse, UserRegisterParams>(
    SESSION_REQUEST.REGISTER,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(ALL_URL.REGISTER, params);
            localStorage.setItem('theme_preference', 'dark-only');
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            // const message: string = error.message;
            const response = JSON.parse(error.request.response);
            if (response.email) {
                return rejectWithValue({
                    status,
                    message: 'El email ya se encuentra registrado',
                });
            } else {
                return rejectWithValue({
                    status,
                    message:
                        'Hubo un error al registrarse, contacte el administrador.',
                });
            }
        }
    },
);

interface RegisterActivateParams {
    uid: string;
    token: string;
}

export const registerActivate = createAsyncThunk<
    Record<string, never>,
    RegisterActivateParams
>(SESSION_REQUEST.REGISTER_ACTIVATE, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(
            ALL_URL.REGISTER_ACTIVATE,
            params,
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

interface RecoverPasswordParams {
    email: string;
}

export const recoverPassword = createAsyncThunk<
    Record<string, never>,
    RecoverPasswordParams
>(SESSION_REQUEST.RECOVER_PASSWORD, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(
            ALL_URL.RECOVER_PASSWORD,
            params,
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

interface RecoverPasswordConfirmParams {
    uid: string;
    token: string;
    new_password: string;
    re_new_password: string;
    onSuccess: () => void;
}

export const recoverPasswordConfirm = createAsyncThunk<
    Record<string, never>,
    RecoverPasswordConfirmParams
>(
    SESSION_REQUEST.RECOVER_PASSWORD_CONFIRM,
    async (params, { rejectWithValue }) => {
        try {
            const { onSuccess, ...postParams } = params;
            const { data } = await axiosInstance.post(
                ALL_URL.RECOVER_PASSWORD_CONFIRM,
                postParams,
            );
            onSuccess();
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
