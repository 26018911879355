import { Button, Card, FormInput, H5 } from 'common';
import { useFormik } from 'formik';
import { ThemeContext } from 'providers/ThemeProvider';
import type { ChangeEvent } from 'react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { loginTOTP } from 'screens/session/sessionService';
import * as Yup from 'yup';
import { getPaymentMethod, setPaymentMethod } from '../../profileService';
import './PaymentMethodEdition.scss';
import { REQUEST_STATE } from '../../../../../redux/types';

interface PaymentMethodEditionProps {
    onClose: () => void;
}

const validationSchema = Yup.object().shape({
    cbu: Yup.string()
        .required('Este campo es requerido')
        .length(20, 'Debe tener 20 digitos'),
    alias: Yup.string().required('Este campo es requerido'),
    pinCode: Yup.string()
        .length(6, 'Este campo es requerido')
        .required('Este campo es requerido'),
});

export const PaymentMethodEdition = (props: PaymentMethodEditionProps) => {
    const dispatch = useAppDispatch();
    const theme = useContext(ThemeContext);
    const [colorMode, setColorMode] = useState('');
    const loginTOTPRequestState = useAppSelector(
        (state) => state.session.requestStates.loginTOTP,
    );
    const { setPaymentMethod: setPaymentMethodReq } = useAppSelector(
        (state) => state.profile.requestStates,
    );

    const loading =
        setPaymentMethodReq === REQUEST_STATE.LOADING ||
        loginTOTPRequestState === REQUEST_STATE.LOADING;

    useEffect(() => {
        if (theme.mix_background_layout === 'dark-only') {
            setColorMode(' dark');
        } else {
            setColorMode('');
        }
    }, [theme]);

    const paymentMethod = useAppSelector(
        (state) => state.profile.paymentMethod,
    );
    const formik = useFormik({
        initialValues: {
            cbu: '',
            alias: '',
            pinCode: '',
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(
                loginTOTP({
                    pinCode: values.pinCode,
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(
                        setPaymentMethod({
                            cbu: values.cbu,
                            alias: values.alias,
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            toast.success(
                                'Se cambiaron los datos bancarios exitosamente',
                            );
                            dispatch(getPaymentMethod());
                            props.onClose();
                        })
                        .catch(() => {
                            toast.error(
                                'Hubo un error al cambiar sus datos bancarios, intentelo nuevamente más tarde.',
                            );
                        });
                })
                .catch(() => {
                    toast.error('Código ingresado incorrecto.');
                });
        },
    });

    useEffect(() => {
        if (paymentMethod)
            // formik set values paymethos without pinCode
            formik.setValues({
                cbu: paymentMethod.cbu,
                alias: paymentMethod.alias,
                pinCode: '',
            });
    }, [paymentMethod]);

    const handleChangeCBU = (e: ChangeEvent) => {
        const event = e as ChangeEvent<HTMLInputElement>;
        const value = event.target.value.toString();
        if (value.length > 20) return;
        formik.setFieldValue('cbu', event.target.value);
    };

    return (
        <div className='bisbull-payment-method-edition'>
            <Card>
                <Col>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row className='mb-5'>
                            <Col>
                                <H5 style={{ fontWeight: 'bold' }}>
                                    Cuenta bancaria
                                </H5>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ minWidth: 300 }}>
                                <FormInput
                                    label='CBU'
                                    name='cbu'
                                    type='number'
                                    value={formik.values.cbu}
                                    onChange={() => null}
                                    onFormikChange={handleChangeCBU}
                                    error={formik.errors.cbu}
                                />
                            </Col>
                            <Col style={{ minWidth: 300 }}>
                                <FormInput
                                    label='Alias'
                                    name='alias'
                                    value={formik.values.alias}
                                    onChange={() => null}
                                    onFormikChange={formik.handleChange}
                                    error={formik.errors.alias}
                                />
                            </Col>
                            <Col
                                style={{ minWidth: 300 }}
                                className={`pin-code-input ${colorMode}`}
                            >
                                <FormInput
                                    label='Ingresá el código de seguridad (2FA) generado'
                                    name='pinCode'
                                    type='pin-password'
                                    value={formik.values.pinCode}
                                    onChange={() => null}
                                    onFormikChange={formik.handleChange}
                                    error={formik.errors.pinCode}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-end mt-4'>
                                <Button
                                    outline
                                    style={{ marginRight: 10 }}
                                    onClick={props.onClose}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type='submit'
                                    loading={loading}
                                    style={{ minWidth: '115px' }}
                                >
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Card>
        </div>
    );
};
