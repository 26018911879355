import { Button, Checkbox, FormInput, P } from '@common';
import { NavLink, useNavigate } from 'react-router-dom';
import { SessionCard } from '../components/SessionCard';
import { Row, Col } from 'reactstrap';
import loginImage from 'assets/images/login_side_image.png';
import { login } from '../sessionService';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { ROUTE_PATH } from 'layout/routes/const';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { UploadImage } from './UploadImage';
import {
    getUserPhotoFace,
    getUserPhotoId,
    getUserPhotoSelfie,
} from '../photoUploadService';
import { sessionActions } from '../sessionSlice';
import { getUserType } from 'screens/user/profile/profileService';

export interface LoginValues {
    email: string;
    password: string;
    rememberPassword: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('El email no es válido')
        .required('Este campo es requerido'),
    password: Yup.string().required('Este campo es requerido'),
    rememberPassword: Yup.string(),
});

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loginState = useAppSelector(
        (state) => state.session.requestStates.login,
    );
    const [renderImg, setRenderImg] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberPassword: 'false',
        },
        onSubmit: () => {
            dispatch(
                login({
                    email: formik.values.email,
                    password: formik.values.password,
                    // onSuccess: () => navigate(ROUTE_PATH.LOGIN2FA),
                    // onSuccess: () => navigate(ROUTE_PATH.ROOT),
                    // onFailure: () => formik.setSubmitting(false),
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(getUserType())
                        .unwrap()
                        .then((res) => {
                            if (res.usertype === 'operator')
                                navigate(ROUTE_PATH.LOGIN2FA);
                            else {
                                dispatch(sessionActions.clearPhoto());
                                Promise.all([
                                    dispatch(getUserPhotoFace()).unwrap(),
                                    dispatch(getUserPhotoId()).unwrap(),
                                    dispatch(getUserPhotoSelfie()).unwrap(),
                                ])
                                    .then(() => {
                                        navigate(ROUTE_PATH.LOGIN2FA);
                                    })
                                    .catch(() => {
                                        setRenderImg(true);
                                    });
                            }
                        })
                        .catch(() => {
                            toast.error(
                                'Hubo un error, vuelve a intentar más tarde.',
                            );
                        });
                })
                .catch(() =>
                    toast.error('El mail y/o contraseña no son correctos.'),
                );
        },
        validationSchema,
    });

    if (renderImg) return <UploadImage />;

    return (
        <div className='login-card'>
            <SessionCard
                title='Ingresar'
                subtitle='Bienvenido, ingresa a tu cuenta'
                src={loginImage}
                onSubmit={formik.handleSubmit}
            >
                <Row>
                    <Col style={{ height: '100px' }}>
                        <FormInput
                            label='Email'
                            name='email'
                            type='email'
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            value={formik.values.email}
                            error={formik.errors.email}
                            placeholder='example@mail.com'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ height: '100px' }}>
                        <FormInput
                            label='Contraseña'
                            name='password'
                            type='password'
                            onFormikChange={formik.handleChange}
                            value={formik.values.password}
                            error={formik.errors.password}
                            onChange={() => null}
                        />
                    </Col>
                </Row>
                <Row className='mb-4' style={{ alignItems: 'center' }}>
                    <Col>
                        <Checkbox
                            name='rememberPassword'
                            label='Recordar contraseña'
                            isChecked={
                                formik.values.rememberPassword === 'true'
                            }
                            onChange={formik.handleChange}
                            value={formik.values.rememberPassword}
                        />
                    </Col>
                    <Col>
                        <P color='primary'>
                            <NavLink to={ROUTE_PATH.RECOVER_PASSWORD}>
                                ¿Olvidaste tu contraseña?
                            </NavLink>
                        </P>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Button
                        type='submit'
                        loading={loginState === REQUEST_STATE.LOADING}
                        style={{ marginLeft: '0px' }}
                    >
                        Ingresar
                    </Button>
                </Row>
                <Row className='justify-content-center m-2'>
                    <P>
                        ¿No tenés una cuenta?
                        <NavLink className='pl-1' to='/registro'>
                            Crear cuenta
                        </NavLink>
                    </P>
                </Row>
            </SessionCard>
        </div>
    );
};
