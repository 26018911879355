import { Card, H5, P } from 'common';
import { CoinComponentObject } from 'common/helper';
import { Table } from 'common/Table';
import { COIN_TYPES } from 'utils/const';
import type { CoinType } from 'utils/types';
import './MarketWalletCard.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';

import { getBalance, getCoins } from 'common/commonService';
import { dotAndComma, getWalletTableData } from 'utils/currency';
import type { WalletDataItem } from '../../wallet/WalletCard';
import { REQUEST_STATE } from 'redux/types';
import { CoinCell } from 'common/Table/common-cells/CoinCell';
import { formatNumberWithCommas } from 'utils/string';

export const MarketWalletCard = () => {
    const dispatch = useAppDispatch();
    const { coins, balances } = useAppSelector((state) => state.common);
    const [tableCoins, setTableCoins] = useState<WalletDataItem[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const coinsState = useAppSelector(
        (state) => state.common.requestStates.coins,
    );
    const balancesState = useAppSelector(
        (state) => state.common.requestStates.getBalance,
    );

    useEffect(() => {
        dispatch(getBalance());
        dispatch(getCoins());
    }, []);

    useEffect(() => {
        if (
            coinsState === REQUEST_STATE.OK &&
            balancesState === REQUEST_STATE.OK
        ) {
            setIsLoading(false);
        }
    }, [coinsState, balancesState]);

    useEffect(() => {
        setTableCoins(getWalletTableData(coins, balances));
    }, [balances]);
    return (
        <div className='market-wallet-card'>
            <Card
                style={{
                    height: '100%',
                    paddingBottom: 10,
                }}
                reactstrapCardBodyStyle={{
                    maxHeight: 'unset',
                }}
            >
                <H5 style={{ fontWeight: 'bold' }}>Mis Saldos</H5>
                <Table
                    loading={isLoading}
                    noDataText='No hay datos'
                    data={tableCoins}
                    columns={[
                        {
                            id: 'coin',
                            header: 'Moneda',
                            cell: (value: CoinType) => (
                                <CoinCell {...CoinComponentObject[value]} />
                            ),
                        },
                        {
                            id: 'amount',
                            header: 'Balance',
                            cell: (value, row) => {
                                if (row.coin === COIN_TYPES.ARS) {
                                    return (
                                        <P
                                            style={{
                                                fontSize: '14px',
                                            }}
                                        >
                                            $
                                            {formatNumberWithCommas(
                                                value,
                                                row.coin,
                                                true,
                                            )}
                                        </P>
                                    );
                                } else {
                                    return (
                                        <div className='coin-cell'>
                                            <P
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {row.coin === COIN_TYPES.USDT
                                                    ? formatNumberWithCommas(
                                                          value,
                                                          row.coin,
                                                          true,
                                                      )
                                                    : dotAndComma(value)}
                                            </P>
                                            <P
                                                color='info'
                                                style={{
                                                    lineHeight: '1.0',
                                                }}
                                            >
                                                {`${dotAndComma(
                                                    row.conversion,
                                                )} ARS`}
                                            </P>
                                        </div>
                                    );
                                }
                            },
                        },
                    ]}
                />
            </Card>
        </div>
    );
};
