import { Button, P } from 'common';
import { Col, Form, Row } from 'reactstrap';
import { getBuyAndSellPrices } from 'utils';
import { MarketInput } from '../MarketInput/MarketInput';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { dotAndComma, formatPrice } from 'utils/currency';
import Big from 'big.js';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { addExchangeQuote } from '../../../quotation/quotationService';
import { ORDER_SIDE } from '../../../quotation/types';
import { formatNumberWithCommas } from 'utils/string';

interface MarketTabProps {
    currentCoinId: string | null;
}

export const MarketTab = (props: MarketTabProps) => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );

    const { currentTPP, baseBalance, quoteBalance } = useMemo(() => {
        const result = {
            currentTPP: null,
            prices: null,
            baseBalance: null,
            quoteBalance: null,
        };
        const foundTPP = tokenPairPrices?.find(
            (tpp) => tpp.tokenpairId.toString() === props.currentCoinId,
        );
        if (!tokenPairPrices || !foundTPP) return result;
        return {
            currentTPP: foundTPP,
            baseBalance: foundTPP.baseBalance,
            quoteBalance: foundTPP.quoteBalance,
        };
    }, [tokenPairPrices]);

    const { requestStates } = useAppSelector((state) => state.quotation);
    const loading = requestStates.addExchangeQuote === 'LOADING';

    const buyFormik = useFormik({
        initialValues: {
            price: '',
            amount: '',
            total: '',
        },
        onSubmit: (values) => {
            if (!currentTPP) return;
            dispatch(
                addExchangeQuote({
                    amount: values.amount,
                    tokenpair_id: currentTPP.tokenpairId,
                    order_side: ORDER_SIDE.BUY,
                    amount_in: currentTPP.quoteCode,
                    symbol: currentTPP.symbol,
                    executeAfterConfirm: null,
                }),
            )
                .unwrap()
                .then(() => {
                    buyFormik.resetForm();
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
                })
                .catch((err) => {
                    toast.error(err.message);
                });
        },
    });

    const sellFormik = useFormik({
        initialValues: {
            price: '',
            amount: '',
            total: '',
        },
        onSubmit: (values) => {
            if (!currentTPP) return;
            dispatch(
                addExchangeQuote({
                    amount: values.amount,
                    tokenpair_id: currentTPP.tokenpairId,
                    order_side: ORDER_SIDE.SELL,
                    amount_in: currentTPP.baseCode,
                    symbol: currentTPP.symbol,
                    executeAfterConfirm: null,
                }),
            )
                .unwrap()
                .then(() => {
                    sellFormik.resetForm();
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
                })
                .catch((err) => {
                    toast.error(err.message);
                });
        },
    });

    const _limitAmount = (value: string, isSelling: boolean) => {
        if (!baseBalance || !quoteBalance) return '0';
        const maxBalance = isSelling ? Big(baseBalance) : Big(quoteBalance);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };

    const handleBuyAmountChange = (value: string) => {
        const limitedValue = _limitAmount(value, false);
        let equivalentTotal = '';
        if (currentTPP && limitedValue) {
            equivalentTotal = getBuyAndSellPrices(
                currentTPP,
                limitedValue,
            ).buy.toFixed(8);
        }
        buyFormik.setFieldValue('amount', limitedValue);
        buyFormik.setFieldValue('total', equivalentTotal);
    };

    const handleSellAmountChange = (value: string) => {
        const limitedValue = _limitAmount(value, true);
        let equivalentTotal = '';
        if (currentTPP && limitedValue) {
            equivalentTotal = getBuyAndSellPrices(
                currentTPP,
                limitedValue,
            ).sell.toFixed(8);
        }
        sellFormik.setFieldValue('amount', limitedValue);
        sellFormik.setFieldValue('total', equivalentTotal);
    };

    return (
        <Row className='input-card-tab-container'>
            <Col>
                <Form onSubmit={buyFormik.handleSubmit}>
                    <Row className='mb-2'>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Disponible
                            </P>
                        </Col>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'right',
                                }}
                            >
                                {`${
                                    quoteBalance
                                        ? // if currentTPP?.quoteCode === 'USDT', use 2 decimals
                                          currentTPP?.quoteCode === 'USDT'
                                            ? formatNumberWithCommas(
                                                  formatPrice(quoteBalance),
                                                  currentTPP?.quoteCode,
                                                  true,
                                              )
                                            : dotAndComma(
                                                  formatPrice(quoteBalance),
                                              )
                                        : ''
                                } ${currentTPP?.quoteCode}`}
                            </P>
                        </Col>
                    </Row>
                    {/* <MarketInput
                        className='mb-2'
                        name='price'
                        type='text'
                        disabled
                        value={buyFormik.values.price}
                        prefix='Precio'
                        suffix={currentTPP?.baseCode || ''}
                    /> */}
                    <MarketInput
                        className='mb-2'
                        name='amount'
                        value={buyFormik.values.amount}
                        onChange={handleBuyAmountChange}
                        onBlur={buyFormik.handleBlur}
                        prefix='Monto'
                        suffix={currentTPP?.quoteCode || ''}
                    />
                    <Button
                        type='submit'
                        className='mb-2'
                        disabled={!buyFormik.values.amount || loading}
                        style={{ width: '100%' }}
                    >
                        {`Comprar ${currentTPP?.baseCode}`}
                    </Button>
                    <MarketInput
                        name='total'
                        disabled
                        value={buyFormik.values.total}
                        prefix='Obtienes'
                        suffix={currentTPP?.baseCode || ''}
                    />
                </Form>
            </Col>
            <Col>
                <Form onSubmit={sellFormik.handleSubmit}>
                    <Row className='mb-2'>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Disponible
                            </P>
                        </Col>
                        <Col>
                            <P
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'right',
                                }}
                            >
                                {`${
                                    baseBalance
                                        ? dotAndComma(formatPrice(baseBalance))
                                        : ''
                                } ${currentTPP?.baseCode}`}
                            </P>
                        </Col>
                    </Row>
                    {/* <MarketInput
                        className='mb-2'
                        name='price'
                        prefix='Precio'
                        disabled
                        value={sellFormik.values.price}
                        suffix={currentTPP?.baseCode || ''}
                    /> */}
                    <MarketInput
                        className='mb-2'
                        name='amount'
                        value={sellFormik.values.amount}
                        onChange={handleSellAmountChange}
                        onBlur={sellFormik.handleBlur}
                        prefix='Monto'
                        suffix={currentTPP?.baseCode || ''}
                    />
                    <Button
                        className='mb-2'
                        disabled={!sellFormik.values.amount || loading}
                        color='secondary'
                        style={{ width: '100%' }}
                    >
                        {`Vender ${currentTPP?.baseCode}`}
                    </Button>
                    <MarketInput
                        name='total'
                        value={sellFormik.values.total}
                        disabled
                        prefix='Obtienes'
                        suffix={currentTPP?.quoteCode || ''}
                    />
                </Form>
            </Col>
        </Row>
    );
};
