import type { CoinType } from '../../../utils/types';
import type { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import type { Coin, TokenPairPrice, TokenPairPrice24hr } from 'common/types';
import { COLOR } from '../../../common/types';
import {
    getCoins,
    getTokenPairPrices,
    getTokenPairPrices24hr,
    getTokenPairs,
} from 'common/commonService';
import { useAppDispatch, useAppSelector } from 'redux/store';
import _ from 'lodash';
import { CoinComponentObject } from 'common/helper';
import { getBuyAndSellPrices, isFormValid, validateInput } from 'utils';
import { convertCryptoUtils, dotAndComma, twoDecimals } from 'utils/currency';
import './Quotation.scss';
import Big from 'big.js';
import { COIN_TYPES } from 'utils/const';
import { quotationActions } from './quotationSlice';
import type { SymbolVariationObj } from './quotationService';
import { addExchangeQuote, getCoinVaration } from './quotationService';
import { ORDER_SIDE } from './types';
import { Button, Card, FormInput, H5, P, Table } from '@common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
    faArrowDown,
    faArrowUp,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Form, Row } from 'reactstrap';
import { formatNumber } from 'utils/string';
import { REQUEST_STATE } from 'redux/types';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { Sparkline } from 'common/Sparkline';
import { CoinCell } from 'common/Table/common-cells/CoinCell';

export interface QuotationTableItem {
    coin: CoinType | null;
    change: { icon: IconDefinition; color: COLOR } | string | null;
    variation: string[] | null;
    buy: string | null;
    sell: string | null;
}

const texts = {
    title: 'Catalogo de criptomonedas',
    subtitle: `En este catalogo encontraras todas las criptomonedas disponibles
                para comprar y vender`,
};

const getTableData = (
    coins: Coin[] | null,
    tokenPairPrices: TokenPairPrice[] | null,
    coinsVariarions: SymbolVariationObj | null,
    tokenPairPrices24hs: TokenPairPrice24hr[] | null,
): QuotationTableItem[] => {
    if (!coins || !tokenPairPrices) return [];
    return _.compact(
        coins.map((coin1) => {
            const object = CoinComponentObject[coin1.tokenCode];
            const foundTPP = tokenPairPrices.find(
                (tpp) => tpp.baseCode === coin1.tokenCode,
            );
            // if (!object || !foundTPP) return null;
            // const { buy, sell } = getSpreadedNetPrice(
            //     foundTPP.netPrice,
            //     foundTPP.fiatSpreadPercentage,
            // );
            // return {
            //     coin: coin1.tokenCode,
            //     change: 0,
            //     variation: [0, 0, 0, 0, 0],
            //     buy: formatNumber(buy),
            //     sell: formatNumber(sell),
            const foundTPPForVariation = tokenPairPrices.find(
                (tpp) =>
                    tpp.quoteCode === COIN_TYPES.USDT &&
                    tpp.baseCode === coin1.tokenCode,
            );
            if (!object || !foundTPP || !coinsVariarions) return null;
            const { sell } = getBuyAndSellPrices(foundTPP, '1');
            const foundVariation = foundTPPForVariation
                ? coinsVariarions[foundTPPForVariation.symbol]
                : null;
            const foundPercVariation = tokenPairPrices24hs?.find((tpp) => {
                return tpp.baseId === parseInt(coin1.id);
            });
            // if (!foundPercVariation) return null;
            const foundPercVariationFixed = foundPercVariation
                ? Big(foundPercVariation.priceChangePercent).toFixed(2)
                : '0';
            if (!foundPercVariationFixed) return null;
            return {
                coin: coin1.tokenCode,
                change: foundPercVariation
                    ? getVariationColorAndIcon(foundPercVariationFixed)
                    : '',
                variation: foundVariation,
                buy: formatNumber(Big(foundTPP.totalPrice)),
                sell: formatNumber(sell),
            };
        }),
    );
};

const getVariationColorAndIcon = (props: string) => {
    if (props === '0') return { icon: faMinus, color: COLOR.dark, value: '0' };
    if (props === undefined) return { icon: faMinus, color: COLOR.dark };
    if (Big(props).gt(0))
        return { icon: faArrowUp, color: COLOR.primary, value: props };
    else return { icon: faArrowDown, color: COLOR.danger, value: props };
};

const getInitialForm = (data: QuotationTableItem[]) => {
    const result: any = {
        values: {},
        errors: {},
        touched: {},
    };
    data.forEach((item) => {
        result.values[item.coin + '-text'] = '';
        result.errors[item.coin + '-text'] = null;
        result.touched[item.coin + '-text'] = false;
    });
    return result;
};

const QuotationMain = () => {
    const dispatch = useAppDispatch();
    const { coins, balances, tokenPairPrices } = useAppSelector(
        (state) => state.common,
    );

    //initialize tableCoins in null in the first render
    const [tableCoins, setTableCoins] = useState<QuotationTableItem[] | null>(
        null,
    );

    const [form, setForm] = useState(getInitialForm(tableCoins || []));

    const [isLoading, setIsLoading] = useState(true);
    const coinVariation = useAppSelector(
        (state) => state.quotation.coinVariation,
    );

    const tokenPairPrice24hs = useAppSelector(
        (state) => state.common.tokenPairPrices24hr,
    );

    const { requestStates } = useAppSelector((state) => state.common);
    const { requestStates: quotationRequestStates } = useAppSelector(
        (state) => state.quotation,
    );
    const tokenPairPricesReq = requestStates.tokenPairPrices;
    const coinsReq = requestStates.coins;
    const tokenPairPrice24hsReq = requestStates.tokenPairPrices24hr;
    const coinVariationReq = quotationRequestStates.getCoinVariation;

    useEffect(() => {
        dispatch(getTokenPairPrices());
        dispatch(getCoins());
        dispatch(getTokenPairPrices24hr());
        dispatch(getTokenPairs())
            .unwrap()
            .then(() => {
                dispatch(getCoinVaration());
            });
    }, []);

    useEffect(() => {
        if (
            coinsReq === REQUEST_STATE.OK &&
            tokenPairPricesReq === REQUEST_STATE.OK &&
            coinVariationReq === REQUEST_STATE.OK &&
            tokenPairPrice24hsReq === REQUEST_STATE.OK
        ) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [coinsReq, tokenPairPricesReq, coinVariationReq, tokenPairPrice24hsReq]);

    useEffect(() => {
        setTableCoins(
            getTableData(
                coins,
                tokenPairPrices,
                coinVariation,
                tokenPairPrice24hs,
            ),
        );
    }, [coinsReq, tokenPairPricesReq, coinVariationReq, tokenPairPrice24hsReq]);

    const handleChange = (
        id: string,
        type: string,
        value: string,
        errorText: string,
    ) => {
        const isValid = validateInput(type, value);
        setForm({
            values: {
                ...form.values,
                [id]: value,
            },
            errors: {
                ...form.errors,
                [id]: isValid ? null : errorText,
            },
            touched: {
                ...form.touched,
                [id]: isValid ? true : form.touched[id],
            },
        });
    };

    const _getMaxBalance = (fromCoinId: string) =>
        convertCryptoUtils.getMaxAmount(balances, fromCoinId);

    const _limitAmount = (fromCoinId: string, value: string): string => {
        if (!balances) return '';
        const maxBalance = _getMaxBalance(fromCoinId);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };

    const validForm = isFormValid(form);

    /**
     * @description gets tokenPair that matches the coin to buy
     * with ARS, gets the updated tokenPairPrice and then
     * issues a walletexchange quote, if successful redirect
     * to buy flow
     * @param row to get row coin im buying
     */
    const handleBuyCoinWithARS = (row: any) => {
        // get from coin (ARS) and to coin (current row clicked) with
        // tokenPairPrice to use the id
        const fromCoin = coins?.find((c) => c.tokenCode === COIN_TYPES.ARS);
        const toCoin = coins?.find((c) => c.tokenCode === row.coin);
        const foundTPP = tokenPairPrices?.find(
            (tp) => tp.quoteCode === COIN_TYPES.ARS && tp.baseCode === row.coin,
        );
        if (!fromCoin || !toCoin || !foundTPP) return;

        console.log('form.values', form.values);
        dispatch(
            addExchangeQuote({
                amount: form.values[row.coin + '-text'],
                tokenpair_id: foundTPP.tokenpairId,
                order_side: ORDER_SIDE.BUY,
                amount_in: foundTPP.quoteCode,
                symbol: foundTPP.symbol,
                executeAfterConfirm: null,
            }),
        )
            .unwrap()
            .then(() => {
                dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
            });
    };

    return (
        <div className='quotation-table'>
            <Card>
                <H5 style={{ fontWeight: 'bold' }}>{texts.title}</H5>
                <P color='info'>{texts.subtitle}</P>
                <Table
                    loading={isLoading}
                    noDataText='No hay datos'
                    data={tableCoins || []}
                    columns={[
                        {
                            id: 'coin',
                            header: 'Moneda',
                            cell: (value: CoinType) => (
                                <CoinCell {...CoinComponentObject[value]} />
                            ),
                        },
                        {
                            id: 'change',
                            header: 'Cambio',
                            cell: (value: {
                                value: string;
                                icon: IconDefinition;
                                color: COLOR;
                            }) => {
                                if (!value.value) return null;
                                return (
                                    <P color={value.color}>
                                        <strong>
                                            <FontAwesomeIcon
                                                icon={value.icon}
                                                className='mr-1'
                                            />
                                            {value.value}%
                                        </strong>
                                    </P>
                                );
                            },
                        },
                        {
                            id: 'variation',
                            header: 'Variación',
                            cell: (value: string[] | null) => {
                                if (!value) return null;
                                return <Sparkline series={value} />;
                            },
                        },
                        {
                            id: 'buy',
                            header: 'Precio compra',
                            cell: (value: string) => {
                                return `${twoDecimals(dotAndComma(value))} ARS`;
                            },
                        },
                        {
                            id: 'sell',
                            header: 'Precio venta',
                            cell: (value: string) => {
                                return `${twoDecimals(dotAndComma(value))} ARS`;
                            },
                        },
                        {
                            id: 'fromWallet',
                            header: 'Operación',
                            cell: (value: string, row: any) => {
                                const foundRowToCoin = coins?.find(
                                    (c) => c.tokenCode === row.coin,
                                );
                                const foundARSCoin = coins?.find(
                                    (c) => c.tokenCode === COIN_TYPES.ARS,
                                );
                                if (!coins || !foundRowToCoin) return null;
                                return (
                                    <Form className='quotation-buy-sell-buttons'>
                                        <Row className='input-and-buttons'>
                                            <FormInput
                                                label=''
                                                name='input1'
                                                type='number'
                                                value={
                                                    form.values[
                                                        row.coin + '-text'
                                                    ] || ''
                                                }
                                                onChange={(newValue) => {
                                                    if (!foundARSCoin) return;
                                                    handleChange(
                                                        row.coin + '-text',
                                                        'input1',
                                                        _limitAmount(
                                                            foundARSCoin?.id.toString(),
                                                            newValue,
                                                        ),
                                                        '',
                                                    );
                                                }}
                                                placeholder='0,00000000'
                                                error={form.errors.input1}
                                            />

                                            <Button
                                                className='buy-button'
                                                disabled={
                                                    !validForm ||
                                                    !form.values[
                                                        row.coin + '-text'
                                                    ] ||
                                                    isLoading
                                                }
                                                onClick={() =>
                                                    handleBuyCoinWithARS(row)
                                                }
                                            >
                                                Comprar
                                            </Button>
                                            <Button
                                                className='sell-button'
                                                color='secondary'
                                                disabled={
                                                    !validForm || isLoading
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        quotationActions.setBuySellCoinValues(
                                                            {
                                                                cryptoCoin:
                                                                    foundRowToCoin.id.toString() as COIN_TYPES,
                                                                isSelling: true,
                                                            },
                                                        ),
                                                    );
                                                    dispatch(
                                                        modalActions.setModalOpen(
                                                            MODAL_OPEN.BUY_DETAIL,
                                                        ),
                                                    );
                                                }}
                                            >
                                                Ir a vender
                                            </Button>
                                        </Row>
                                        <Row>
                                            {foundARSCoin && (
                                                <div className='linked-text'>
                                                    <P
                                                        color='primary'
                                                        onClick={() => {
                                                            handleChange(
                                                                row.coin +
                                                                    '-text',
                                                                'input1',
                                                                _getMaxBalance(
                                                                    foundARSCoin.id.toString(),
                                                                ).toString(),

                                                                '',
                                                            );
                                                        }}
                                                    >
                                                        {`Usar todo (${dotAndComma(
                                                            _getMaxBalance(
                                                                foundARSCoin.id.toString(),
                                                            ).toString(),
                                                        )} ARS)`}
                                                    </P>
                                                </div>
                                            )}
                                        </Row>
                                    </Form>
                                );
                            },
                        },
                    ]}
                />
            </Card>
        </div>
    );
};

export const Quotation = () => {
    return <QuotationMain />;
};
