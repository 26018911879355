import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { SessionCard } from '../components/SessionCard';
import login2FAImage from '../../../assets/images/login_2fa_side_image.png';
import { Col, Row } from 'reactstrap';
import { Button, FormInput } from '@common';
import { Check, X } from 'react-feather';
import { REQUEST_STATE } from '../../../redux/types';
import './UploadImage.scss';
import { LoaderWheel } from '../../../common/Loader/LoaderWheel';
import { ROUTE_PATH } from '../../../layout/routes/const';
import { useNavigate } from 'react-router-dom';
import {
    setUserPhotoFace,
    setUserPhotoId,
    setUserPhotoSelfie,
} from '../photoUploadService';

export const UploadImage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { requestStates } = useAppSelector((state) => state.session);
    const { userPhotoFace, userPhotoId, userPhotoSelfie } = useAppSelector(
        (state) => state.session,
    );

    const loading =
        requestStates.setUserPhotoFace === REQUEST_STATE.LOADING ||
        requestStates.setUserPhotoId === REQUEST_STATE.LOADING ||
        requestStates.setUserPhotoSelfie === REQUEST_STATE.LOADING;

    const formik = useFormik({
        initialValues: {
            face: '',
            idFront: '',
            idSelfie: '',
        },
        //handle validation schema
        // validationSchema,
        onSubmit: () => {
            navigate(ROUTE_PATH.LOGIN2FA);
        },
    });

    const handleUploadImage = (id: string, value: File): boolean => {
        if (value.size < 990000) {
            if (id === 'face') {
                dispatch(setUserPhotoFace({ image: value }))
                    .then(() => {
                        formik.setFieldValue(id, value.name);
                    })
                    .catch(() => {
                        toast.error('Hubo un error al subir el archivo');
                    });
            }
            if (id === 'idFront') {
                dispatch(setUserPhotoId({ image: value }))
                    .then(() => {
                        formik.setFieldValue(id, value.name);
                    })
                    .catch(() => {
                        toast.error('Hubo un error al subir el archivo');
                    });
            }
            if (id === 'idSelfie') {
                dispatch(setUserPhotoSelfie({ image: value }))
                    .then(() => {
                        formik.setFieldValue(id, value.name);
                    })
                    .catch(() => {
                        toast.error('Hubo un error al subir el archivo');
                    });
            }
            return true;
        } else {
            toast.error('El archivo no puede superar los 1MB');
            return false;
        }
    };

    const hasAllPhotos = !!userPhotoFace && !!userPhotoId && !!userPhotoSelfie;

    if (loading) return <LoaderWheel />;
    return (
        <div>
            <div className='login-card'>
                <SessionCard
                    title='Activación de tu cuenta'
                    subtitle='Clickea en el botón para confirmar la activación de tu cuenta.'
                    src={login2FAImage}
                    onSubmit={formik.handleSubmit}
                >
                    <div className='second-step-register'>
                        <Row>
                            <Col className='col-11'>
                                <FormInput
                                    label='Foto de tu rostro'
                                    type='file'
                                    name='face'
                                    value={formik.values.face}
                                    onChange={() => null}
                                    onFileChange={(newFiles) => {
                                        const file = newFiles[0];
                                        if (!file) return;
                                        handleUploadImage('face', file);
                                    }}
                                    error={formik.errors.face}
                                    disabled={loading}
                                />
                            </Col>
                            <Col className='col-1'>
                                {!!userPhotoFace ? (
                                    <Check
                                        style={{
                                            color: 'green',
                                            marginTop: 30,
                                        }}
                                    />
                                ) : (
                                    <X
                                        style={{ color: 'red', marginTop: 30 }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-11'>
                                <FormInput
                                    label='Foto de tu DNI frente'
                                    type='file'
                                    name='idFront'
                                    value={formik.values.idFront}
                                    onChange={() => null}
                                    onFileChange={(newFiles) => {
                                        const file = newFiles[0];
                                        if (!file) return;
                                        handleUploadImage('idFront', file);
                                    }}
                                    error={formik.errors.idFront}
                                    disabled={loading}
                                />
                            </Col>
                            <Col className='col-1'>
                                {!!userPhotoId ? (
                                    <Check
                                        style={{
                                            color: 'green',
                                            marginTop: 30,
                                        }}
                                    />
                                ) : (
                                    <X
                                        style={{ color: 'red', marginTop: 30 }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-11'>
                                <FormInput
                                    label='Selfie sosteniendo tu DNI'
                                    type='file'
                                    name='idSelfie'
                                    value={formik.values.idSelfie}
                                    onChange={() => null}
                                    onFileChange={(newFiles) => {
                                        const file = newFiles[0];
                                        if (!file) return;
                                        handleUploadImage('idSelfie', file);
                                    }}
                                    error={formik.errors.idSelfie}
                                    disabled={loading}
                                />
                            </Col>
                            <Col className='col-1'>
                                {!!userPhotoSelfie ? (
                                    <Check
                                        style={{
                                            color: 'green',
                                            marginTop: 30,
                                        }}
                                    />
                                ) : (
                                    <X
                                        style={{ color: 'red', marginTop: 30 }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Button
                                disabled={!hasAllPhotos}
                                type='submit'
                                loading={loading}
                                style={{
                                    marginLeft: '15px',
                                    marginTop: '15px',
                                }}
                            >
                                Siguiente
                            </Button>
                        </Row>
                    </div>
                </SessionCard>
            </div>
        </div>
    );
};
