import { Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { Section } from './Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import { layoutActions } from 'layout/layoutSlice';
import { useMobile } from './useMobile';
import { CryptoCard } from './CryptoCard/CryptoCard';
import { useState, useEffect } from 'react';
import { getAvatar } from '../../screens/user/profile/profileService';
import { REQUEST_STATE } from '../../redux/types';
import { LoaderWheel } from '../../common/Loader/LoaderWheel';
import { ROUTE_PATH } from '../routes/const';
import { useLoadedImage } from 'utils';
import type { ReactNode } from 'react';

interface CommonSidebarProps {
    renderTopComponent: ReactNode;
    sections: Section[];
}

export const CommonSidebar = (props: CommonSidebarProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);
    const { isMobile } = useMobile();
    const { avatar } = useAppSelector((state) => state.profile);
    const { requestStates } = useAppSelector((state) => state.profile);
    const loading =
        requestStates.getAvatar === REQUEST_STATE.LOADING &&
        location.pathname !== ROUTE_PATH.PROFILE + '/ajustes';
    const loaded = useLoadedImage(avatar);

    const [mobile, setMobile] = useState(false);

    // if windw with is < 690, starts withs close sidebar
    useEffect(() => {
        if (window.screen.width < 690) {
            dispatch(layoutActions.setSidebarOpen(false));
            setMobile(true);
        } else {
            dispatch(layoutActions.setSidebarOpen(true));
            setMobile(false);
        }
    }, [window.screen.width]);

    useEffect(() => {
        dispatch(getAvatar());
    }, []);

    const closeSidebar = () => {
        if (mobile) {
            dispatch(layoutActions.setSidebarOpen(false));
        } else {
            dispatch(layoutActions.setSidebarOpen(true));
        }
    };

    return (
        <header
            className={`sidebar main-nav ${!sidebarOpen ? 'close_icon' : ''}`}
        >
            <div className='sidebar-user'>
                <Row>
                    <Col className='col-3 image-container'>
                        {loading ? (
                            <LoaderWheel />
                        ) : (
                            <img
                                style={{
                                    display: !!loaded ? 'block' : 'none',
                                }}
                                className='img-70 rounded-circle'
                                src={avatar}
                                alt=''
                            />
                        )}
                    </Col>
                    {props.renderTopComponent}
                </Row>
            </div>
            <nav className='sidebar-nav'>
                <div className='main-navbar'>
                    <div id='mainnav'>
                        <ul className='nav-menu custom-scrollbar'>
                            <li className='sidebar-main-title'></li>
                            {props.sections.map((section, index) => (
                                <li
                                    key={`sidebar-section-${index}`}
                                    style={{ marginTop: '10px' }}
                                >
                                    <NavLink
                                        className='nav-link menu-title'
                                        to={section.href}
                                        onClick={() => closeSidebar()}
                                    >
                                        {section.icon &&
                                        typeof section.icon === 'string' ? (
                                            <i
                                                className={`${section.icon}`}
                                            ></i>
                                        ) : (
                                            section.icon
                                        )}

                                        <span>{section.title}</span>
                                        <div className='according-menu'>
                                            <i
                                                className='fa fa-angle-right'
                                                style={{
                                                    position: 'absolute',
                                                }}
                                            ></i>
                                        </div>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
            <CryptoCard />
        </header>
    );
};
