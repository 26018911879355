import { Button, Card, FormInput, H5, Modal, P } from 'common';
import { CoinComponentObject } from 'common/helper';
import { Table } from 'common/Table';
import { COIN_TYPES } from 'utils/const';
import type { CoinType } from 'utils/types';
import './WalletCard.scss';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import type { RootState } from '../../../../redux/store';
import { useEffect, useState } from 'react';
import {
    addBalance,
    getBalanceExtended,
    getCoins,
} from '../../../../common/commonService';
import {
    getSingleCoinIDFromName,
    getWalletTableData,
    dotAndComma,
} from '../../../../utils/currency';
import { MODAL_OPEN } from '../../../../common/Modal/const';
import { useSelector } from 'react-redux';
import { modalActions } from '../../../../common/Modal/modalSlice';
import { Form } from 'reactstrap';
import { useFormik } from 'formik';
import { Check, Minus, Plus, X } from 'react-feather';
import { REQUEST_STATE } from '../../../../redux/types';
import Big from 'big.js';
import { toast } from 'react-toastify';
import { formatNumber, formatNumberWithCommas } from 'utils/string';
import { CoinCell } from 'common/Table/common-cells/CoinCell';

export interface WalletDataItem {
    coin: CoinType;
    amount: string;
    conversion: string;
}

export const WalletCard = () => {
    const dispatch = useAppDispatch();
    const { coins, balances } = useAppSelector((state) => state.common);
    const { modalOpen } = useSelector((state: RootState) => state.modal);
    const [tableCoins, setTableCoins] = useState<WalletDataItem[]>([]);
    const [updateModalData, setUpdateModalData] = useState<any>(null);
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalBalance, setTotalBalance] = useState(Big('0'));
    const [balanceCheck, setBalanceCheck] = useState(false);
    const coinsState = useAppSelector(
        (state) => state.common.requestStates.coins,
    );
    const balancesState = useAppSelector(
        (state) => state.common.requestStates.getBalance,
    );

    const formik = useFormik({
        initialValues: {
            add: false,
            amount: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
    });

    const closeModal = () => {
        formik.setValues({
            add: false,
            amount: '',
        });
        setConfirmUpdate(false);
        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
    };

    useEffect(() => {
        dispatch(getBalanceExtended());
        dispatch(getCoins());
    }, []);

    useEffect(() => {
        if (
            coinsState === REQUEST_STATE.OK &&
            balancesState === REQUEST_STATE.OK
        ) {
            setIsLoading(false);
        }
    }, [coinsState, balancesState]);

    useEffect(() => {
        setTableCoins(getWalletTableData(coins, balances));
    }, [balances]);

    useEffect(() => {
        if (!updateModalData || !formik.values.amount) return;
        const total = formik.values.add
            ? Big(updateModalData?.amount).add(formik?.values.amount)
            : Big(updateModalData?.amount).sub(formik?.values.amount);
        setTotalBalance(total);
        total.lt(Big('0')) || Big(formik.values.amount).eq(0)
            ? setBalanceCheck(true)
            : setBalanceCheck(false);
    }, [formik?.values.amount, updateModalData?.amount, formik?.values.add]);

    return (
        <Card className='wallet-card'>
            <H5 style={{ fontWeight: 'bold' }}>Billetera</H5>
            <Table
                loading={isLoading}
                noDataText='No hay datos'
                data={tableCoins}
                columns={[
                    {
                        id: 'coin',
                        header: 'Moneda',
                        cell: (value: CoinType) => {
                            return <CoinCell {...CoinComponentObject[value]} />;
                        },
                    },
                    {
                        id: 'amount',
                        header: 'Balance',
                        cell: (value, row) => {
                            const coinName = row.coin;
                            if (row.coin === COIN_TYPES.ARS) {
                                return (
                                    <P
                                        style={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            minWidth: 150,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {formatNumberWithCommas(
                                            value,
                                            'ARS',
                                            true,
                                        )}{' '}
                                        ARS
                                    </P>
                                );
                            } else {
                                return (
                                    <div style={{ width: '100px' }}>
                                        <P
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                minWidth: 150,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {coinName === COIN_TYPES.USDT
                                                ? formatNumberWithCommas(
                                                      value,
                                                      'USDT',
                                                      coinName,
                                                  ) + ' USDT'
                                                : dotAndComma(value) +
                                                  ' ' +
                                                  coinName}
                                        </P>
                                        <P
                                            color='info'
                                            style={{
                                                lineHeight: '1.0',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            ≈ {dotAndComma(row.conversion)} ARS
                                        </P>
                                    </div>
                                );
                            }
                        },
                    },
                    {
                        id: 'buttons',
                        header: '',
                        cell: (value, row) => {
                            return (
                                <div className='wallet-card-buttons'>
                                    <Button
                                        onClick={() => {
                                            dispatch(
                                                modalActions.setModalOpen(
                                                    MODAL_OPEN.OPERATOR_UPDATE_BALANCE,
                                                ),
                                            );
                                            setUpdateModalData(row);
                                        }}
                                    >
                                        Actualizar
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
            />
            <Modal
                className='wallet-modal-modal'
                title={'Actualizar saldo de ' + updateModalData?.coin}
                open={
                    modalOpen === MODAL_OPEN.OPERATOR_UPDATE_BALANCE &&
                    updateModalData
                }
                onClose={() => {
                    closeModal();
                }}
                hideClose
            >
                <Form onSubmit={formik.handleSubmit} className='mt-4'>
                    <div className='form'>
                        <div
                            className={
                                'plus-minus-toggle' +
                                (formik.values.add ? ' add' : '')
                            }
                            onClick={() =>
                                formik.setFieldValue('add', !formik.values.add)
                            }
                        >
                            {formik.values.add && <Plus />}
                            {!formik.values.add && <Minus />}
                        </div>
                        <FormInput
                            name='amount'
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.amount}
                            placeholder={
                                updateModalData?.amount +
                                ' ' +
                                updateModalData?.coin
                            }
                        />
                        <P style={{ display: 'flex', alignItems: 'center' }}>
                            {updateModalData?.coin}
                        </P>
                    </div>
                    <div>
                        <P style={{ margin: '5px 0' }}>
                            {`El saldo será modificado a ${dotAndComma(
                                formatNumber(totalBalance),
                            )} ${updateModalData?.coin}`}
                        </P>
                    </div>
                    <div className='buttons'>
                        <Button
                            disabled={confirmUpdate || balanceCheck}
                            onClick={() => setConfirmUpdate(true)}
                            style={{
                                marginRight: 5,
                            }}
                        >
                            Aceptar
                        </Button>
                        {confirmUpdate && (
                            <>
                                <Button
                                    color='danger'
                                    onClick={() => setConfirmUpdate(false)}
                                    style={{
                                        padding: '5px 8px',
                                        height: 35,
                                        marginRight: 5,
                                    }}
                                >
                                    <X />
                                </Button>
                                <Button
                                    type='submit'
                                    style={{
                                        padding: '5px 8px',
                                        height: 35,
                                        marginRight: 5,
                                    }}
                                    onClick={() => {
                                        const amount: string = formik.values.add
                                            ? formik.values.amount
                                            : (-formik.values
                                                  .amount).toString();
                                        dispatch(
                                            addBalance({
                                                currency_id:
                                                    getSingleCoinIDFromName(
                                                        coins,
                                                        updateModalData.coin,
                                                    ),
                                                amount_add: amount.toString(),
                                            }),
                                        )
                                            .unwrap()
                                            .then(() => {
                                                dispatch(getBalanceExtended());
                                                closeModal();
                                            })
                                            .catch(() => {
                                                toast.error(
                                                    'Hubo un error al agregar balance, intentelo nuevamente más tarde.',
                                                );
                                                closeModal();
                                            });
                                    }}
                                >
                                    <Check />
                                </Button>
                                <P>Confirmar?</P>
                            </>
                        )}
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};
