import Big, { Comparison } from 'big.js';
import type { Big as BigObject } from 'big.js';
import { dotAndComma } from './currency';

export const capitalize = (s: string): string =>
    s
        ? s
              .split(' ')
              .map((ss) => ss[0].toUpperCase() + ss.slice(1).toLowerCase())
              .join(' ')
        : '';

/**
 * formatNumber
 * ============
 * @description given a Big number, return one with 8 max decimals,
 * or less if has less
 * @param number Big object
 * @returns string of formatted value
 *
 * Examples:
 * Big("12345") -> "12345"
 * Big("1.00123") -> "1.00123"
 * Big("1.1000000000") -> "1.1"
 * Big("1.1234567891") -> "1.12345678"
 * Big("1.12300000000000123") -> "1.123"
 */
export const formatNumber = (number: BigObject): string => {
    const fixedString = Big(number).toFixed(8);
    const splitted = fixedString.split('.');
    const decimals = splitted[1];
    if (!decimals) return fixedString;
    const newDecimals = [];
    let extraZeroDecimal = true;
    for (let i = decimals.length - 1; i >= 0; i--) {
        const char = decimals[i];
        if (char !== '0') {
            newDecimals.push(char);
            extraZeroDecimal = false;
        } else {
            // ignore if (extraZeroDecimal)
            if (!extraZeroDecimal) {
                newDecimals.push(char);
            }
        }
    }
    const reversedDecimals = [...newDecimals].reverse();
    if (newDecimals.length === 0) return splitted[0];
    return [splitted[0], reversedDecimals.join('')].join('.');
};

export const formatNumberWithCommas = (
    amount: string,
    symbol?: string,
    twoDecimal?: boolean,
): string => {
    //problem when integer has 6 decimals, big translates it to 1e-6.
    const value = Big(amount).lt(0.000001)
        ? dotAndComma(amount)
        : dotAndComma(Big(amount).toString());
    if (twoDecimal) return dotAndComma(Big(amount).toFixed(2));
    if (twoDecimal && symbol)
        return `${dotAndComma(Big(amount).toFixed(2))} ${symbol}`;
    return symbol ? `${value} ${symbol}` : value;
};
