import { Button, P, Table } from '@common';
import { getTokenPairPrices } from 'common/commonService';
import { ExchangeOrderStatusText } from 'common/Table/common-cells';
import { formatISODate } from 'common/Table/utils';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import {
    reactivateUserExchangeLimit,
    suspendUserExchangeLimit,
    getExchangeOrder,
} from 'screens/operator/operatorService';
import type { OperatorExchangeOrder } from 'screens/operator/types';
import {
    getOrderSideText,
    getOrderTypeData,
} from 'screens/user/market/OrdersTablesCard/OpenOrdersTableTab';
import { personalData } from 'screens/user/profile/profileService';
import { dotAndComma } from 'utils';
import { formatNumber, formatNumberWithCommas } from 'utils/string';
export interface DashboardOrdersProps {
    data: OperatorExchangeOrder[];
    loading: boolean;
    noDataText: string;
}

export const OpenOrders = (props: DashboardOrdersProps) => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );
    const operatorId = useAppSelector((state) => state.profile.userData?.id);
    const {
        getExchangeOrder: getExchangeOrderReq,
        suspendUserExchangeLimit: suspendedUserExchangeLimitReq,
        reactivateUserExchangeLimit: reactivateUserExchangeLimitReq,
    } = useAppSelector((state) => state.operator.requestStates);

    const loadingReq =
        getExchangeOrderReq === REQUEST_STATE.LOADING ||
        suspendedUserExchangeLimitReq === REQUEST_STATE.LOADING ||
        reactivateUserExchangeLimitReq === REQUEST_STATE.LOADING;

    useEffect(() => {
        dispatch(personalData());
        dispatch(getTokenPairPrices());
    }, []);

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                noDataText={props.noDataText}
                data={props.data}
                columns={[
                    {
                        id: 'id',
                        header: 'Número de operación',
                    },
                    {
                        id: '__fullname',
                        header: 'Nombre completo',
                        cell: (value, row) => `${row.name} ${row.lastname}`,
                    },
                    {
                        id: 'email',
                        header: 'Email',
                    },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (cell, row) => formatISODate(row.createdAt),
                    },
                    {
                        id: 'tokenpairId',
                        header: 'Par',
                        cell: (cell) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === cell,
                            );
                            if (!tokenPairPrices || !foundTPP) return '';
                            return (
                                <P style={{ fontWeight: 'bold' }}>
                                    {foundTPP.symbolPretty}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderType',
                        header: 'Tipo',
                        cell: (cell) => {
                            const orderTypeData = getOrderTypeData(cell);
                            return (
                                <P color={orderTypeData.color}>
                                    {orderTypeData.text}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderSide',
                        header: 'Compra/Venta',
                        cell: getOrderSideText,
                    },
                    {
                        id: 'amount',
                        header: 'Cantidad',
                        cell: (value, row) =>
                            row.amountIn === 'ARS' || row.amountIn === 'USDT'
                                ? formatNumberWithCommas(
                                      value,
                                      row.amountIn,
                                      true,
                                  ) +
                                  ' ' +
                                  row.amountIn
                                : `${dotAndComma(formatNumber(value))} ${
                                      row.amountIn
                                  }`,
                    },
                    {
                        id: 'priceTarget',
                        header: 'Precio Objetivo',
                        cell: (value, row) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === row.tokenpairId,
                            );
                            if (!tokenPairPrices || !foundTPP) return ' ';

                            return `${dotAndComma(formatNumber(value))} ${
                                foundTPP.quoteCode
                            }`;
                        },
                    },
                    {
                        id: 'active-executed',
                        header: 'Estado',
                        cell: (cell, row) => {
                            const { active, executed, changeStateBy } = row;
                            return (
                                <ExchangeOrderStatusText
                                    active={active}
                                    executed={executed}
                                    changeStateBy={changeStateBy}
                                />
                            );
                        },
                    },
                    {
                        id: '__actions',
                        header: 'Acciones',
                        cell: (cell, row) => {
                            const { active, executed } = row;
                            const isActive = active && !executed;
                            const isSuspendedByOperator =
                                !active &&
                                !executed &&
                                row.changeStateBy === operatorId;
                            if (isSuspendedByOperator) {
                                return (
                                    <Button
                                        disabled={loadingReq}
                                        onClick={() => {
                                            dispatch(
                                                reactivateUserExchangeLimit({
                                                    id: row.exchange,
                                                }),
                                            )
                                                .unwrap()
                                                .then(() => {
                                                    dispatch(
                                                        getExchangeOrder(),
                                                    );
                                                })
                                                .catch(() => {
                                                    toast.error(
                                                        'Hubo un error al suspender la orden de límite, intentelo nuevamente más tarde.',
                                                    );
                                                });
                                        }}
                                    >
                                        Reactivar
                                    </Button>
                                );
                            }
                            if (isActive) {
                                return (
                                    <div className='d-flex flex-row flex-nowrap'>
                                        <Button
                                            color='danger'
                                            disabled={loadingReq}
                                            onClick={() => {
                                                dispatch(
                                                    suspendUserExchangeLimit({
                                                        id: row.exchange,
                                                    }),
                                                )
                                                    .unwrap()
                                                    .then(() => {
                                                        dispatch(
                                                            getExchangeOrder(),
                                                        );
                                                    })
                                                    .catch(() => {
                                                        toast.error(
                                                            'Hubo un error al suspender la orden de límite, intentelo nuevamente más tarde.',
                                                        );
                                                    });
                                            }}
                                        >
                                            Suspender
                                        </Button>
                                    </div>
                                );
                            }
                            return null;
                        },
                    },
                ]}
            />
        </div>
    );
};
