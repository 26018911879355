import { createAsyncThunk } from '@reduxjs/toolkit';
import { ALL_URL } from 'redux/url';
import { axiosInstance } from 'utils/axios/index';

enum WALLET_REQUEST {
    GET_EXCHANGE_WITHDRAWAL = 'GET_EXCHANGE_WITHDRAWAL',
    ADD_EXCHANGE_WITHDRAWAL = 'ADD_EXCHANGE_WITHDRAWAL',
    GET_EXCHANGE_DEPOSIT = 'GET_EXCHANGE_DEPOSIT',
    GET_EXCHANGE_WITHDRAWAL_CRYPTO = 'GET_EXCHANGE_WITHDRAWAL_CRYPTO',
    DEPOSIT_ARS = 'DEPOSIT_ARS',
    USER_CRYPTO_ADDRESS = 'USER_CRYPTO_ADDRESS',
    GET_CRYPTO_NETWORKS = 'GET_CRYPTO_NETWORKS',
    GET_CURRENCY_CRYPTO_NETWORK = 'GET_CURRENCY_CRYPTO_NETWORK',
    ADD_EXCHANGE_WITHDRAWAL_CRYPTO = 'ADD_EXCHANGE_WITHDRAWAL_CRYPTO',
}

export interface ExchangeWithdrawalResponseItem {
    id: number;
    currency_id: number;
    amount: string;
    created_at: string;
    updated_at: string;
    change_state_by: number;
    change_state_at: string;
    active: boolean;
    executed: boolean;
}
type GetExchangeWithdrawalResponse = ExchangeWithdrawalResponseItem[];

export const getExchangeWithdrawal =
    createAsyncThunk<GetExchangeWithdrawalResponse>(
        WALLET_REQUEST.GET_EXCHANGE_WITHDRAWAL,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.EXCHANGE_WITHDRAWAL,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );
type AddExchangeWithdrawalResponse = ExchangeWithdrawalResponseItem;
interface AddExchangeWithdrawalParams {
    currency_id: number;
    amount: string;
}

export const addExchangeWithdrawal = createAsyncThunk<
    AddExchangeWithdrawalResponse,
    AddExchangeWithdrawalParams
>(
    WALLET_REQUEST.ADD_EXCHANGE_WITHDRAWAL,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.EXCHANGE_WITHDRAWAL,
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
export interface ExchangeDepositResponseItem {
    id: number;
    currency_id: number;
    amount: string;
    reference_id: string;
    created_at: string;
    updated_at: string;
    change_state_by: number;
    change_state_at: string;
    active: boolean;
    executed: boolean;
}

type GetExchangeWithdrawalCryptoResponse =
    ExchangeWithdrawalCryptoResponseItem[];

export const getExchangeWithdrawalCrypto =
    createAsyncThunk<GetExchangeWithdrawalCryptoResponse>(
        WALLET_REQUEST.GET_EXCHANGE_WITHDRAWAL_CRYPTO,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.EXCHANGE_WITHDRAWAL_CRYPTO,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );
export interface DepositARSResponse {
    id: string;
    currency_id: string;
}

export const getDepositARSInfo = createAsyncThunk<DepositARSResponse>(
    WALLET_REQUEST.DEPOSIT_ARS,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.DEPOSIT_ARS);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface SetDepositCryptoParams {
    cryptonetwork_id: number;
    currency_id: number;
}

export interface DepositCryptoResponse {
    address: string;
    cryptonetwork_id: string;
}

export const getDepositCryptoInfo = createAsyncThunk<
    DepositCryptoResponse,
    SetDepositCryptoParams
>(WALLET_REQUEST.USER_CRYPTO_ADDRESS, async (params, { rejectWithValue }) => {
    try {
        const { data } = await axiosInstance.post(
            ALL_URL.USER_CRYPTO_ADDRESS,
            params,
        );
        return data;
    } catch (error: any) {
        const status: number = error.response.status;
        const message: string = error.message;
        return rejectWithValue({ status, message });
    }
});

export interface CryptoNetworkResponseItem {
    id: number;
    name: string;
    created_at: string;
    active: boolean;
}

type GetCryptoNetworksResponse = CryptoNetworkResponseItem[];

export const getCryptoNetworks = createAsyncThunk<GetCryptoNetworksResponse>(
    WALLET_REQUEST.GET_CRYPTO_NETWORKS,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(
                ALL_URL.GET_CRYPTO_NETWORKS,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

export interface CurrencyCryptoNetworkResponseItem {
    currency_id: number;
    cryptonetwork_id: number;
    created_at: string;
    active: boolean;
}

type GetCurrencyCryptoNetworkResponse = CurrencyCryptoNetworkResponseItem[];

export const getCurrencyCryptoNetwork =
    createAsyncThunk<GetCurrencyCryptoNetworkResponse>(
        WALLET_REQUEST.GET_CURRENCY_CRYPTO_NETWORK,
        async (params, { rejectWithValue }) => {
            try {
                const { data } = await axiosInstance.get(
                    ALL_URL.GET_CURRENCY_CRYPTO_NETWORK,
                );
                return data;
            } catch (error: any) {
                const status: number = error.response.status;
                const message: string = error.message;
                return rejectWithValue({ status, message });
            }
        },
    );

export interface ExchangeWithdrawalCryptoResponseItem {
    id: number;
    currency_id: number;
    address: string;
    cryptonetwork_id: number;
    amount: string;
    created_at: string;
    updated_at: string;
    change_state_by: number;
    change_state_at: string;
    active: boolean;
    executed: boolean;
}

type GetExchangeDepositResponse = ExchangeDepositResponseItem[];

export const getExchangeDeposit = createAsyncThunk<GetExchangeDepositResponse>(
    WALLET_REQUEST.GET_EXCHANGE_DEPOSIT,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.get(ALL_URL.EXCHANGE_DEPOSIT);
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);

interface AddExchangeWithdrawalCryptoParams {
    currency_id: number;
    address: string;
    cryptonetwork_id: number;
    amount: number;
}

type AddExchangeWithdrawalCryptoResponse =
    ExchangeWithdrawalCryptoResponseItem[];

export const addExchangeWithdrawalCrypto = createAsyncThunk<
    AddExchangeWithdrawalCryptoResponse,
    AddExchangeWithdrawalCryptoParams
>(
    WALLET_REQUEST.ADD_EXCHANGE_WITHDRAWAL_CRYPTO,
    async (params, { rejectWithValue }) => {
        try {
            const { data } = await axiosInstance.post(
                ALL_URL.EXCHANGE_WITHDRAWAL_CRYPTO,
                params,
            );
            return data;
        } catch (error: any) {
            const status: number = error.response.status;
            const message: string = error.message;
            return rejectWithValue({ status, message });
        }
    },
);
