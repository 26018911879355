import { P, Button, BuyModal } from 'common';
import { NavLink } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { InputAmount } from '../InputAmount';
import { Col, Form, Row } from 'reactstrap';
import { convertCryptoUtils, dotAndComma, twoDecimals } from 'utils/currency';
import type { CoinComponentValue } from 'common/helper';
import { CoinComponentObject } from 'common/helper';
import Big from 'big.js';
import { useFormik } from 'formik';
import type { Coin } from '../../../../common/types';
import { quotationActions } from '../quotationSlice';
import { COIN_TYPES } from 'utils/const';
import { addExchangeQuote } from '../quotationService';
import { toast } from 'react-toastify';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { ORDER_SIDE } from '../types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import {
    getBalance,
    getCoins,
    getTokenPairPrices,
    getTokenPairs,
} from 'common/commonService';
import { formatNumberWithCommas } from 'utils/string';

export interface BuyDetailsState {
    coinFrom: CoinComponentValue;
    coinTo: CoinComponentValue;
    fromAmount: string;
    amountTo: string;
    coinFromCoin: Coin;
    coinToCoin: Coin;
    coinFromMaxBalance: string;
}

export const Details = () => {
    const dispatch = useAppDispatch();
    const { coins, balances, tokenPairPrices } = useAppSelector(
        (state) => state.common,
    );
    const { buySellCoinValues } = useAppSelector((state) => state.quotation);
    const { modalOpen } = useAppSelector((state) => state.modal);

    const formik = useFormik({
        initialValues: {
            fromAmount: '',
            toAmountEquivalent: '',
        },
        // Note: wrongfuly managed in the button onClick
        onSubmit: () => {
            console.log('submit');
        },
    });

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getBalance());
        dispatch(getTokenPairPrices());
        dispatch(getTokenPairs());
    }, []);

    const { fromCoin, toCoin } = useMemo(() => {
        const result: { fromCoin: Coin | null; toCoin: Coin | null } = {
            fromCoin: null,
            toCoin: null,
        };
        if (!coins || !buySellCoinValues) return result;
        coins.forEach((c) => {
            if (buySellCoinValues.isSelling) {
                if (buySellCoinValues.cryptoCoin === c.id.toString())
                    result.fromCoin = c;
                if (COIN_TYPES.ARS === c.tokenCode) result.toCoin = c;
            } else {
                if (buySellCoinValues.cryptoCoin === c.id.toString())
                    result.toCoin = c;
                if (COIN_TYPES.ARS === c.tokenCode) result.fromCoin = c;
            }
        });
        return result;
    }, [coins, buySellCoinValues]);

    const _getMaxBalance = (fromCoinId: string) =>
        convertCryptoUtils.getMaxAmount(balances, fromCoinId);

    const _limitAmount = (fromCoinId: string, value: string): string => {
        if (!balances) return '';
        const maxBalance = _getMaxBalance(fromCoinId);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };

    const handleFromChange = (value: string) => {
        if (!fromCoin || !toCoin) return;
        const limitedFromValue = _limitAmount(fromCoin.id.toString(), value);
        formik.setFieldValue('fromAmount', limitedFromValue);
        formik.setFieldValue(
            'toAmountEquivalent',
            convertCryptoUtils.getToAmountEquivalent(coins, tokenPairPrices, {
                fromCoinId: fromCoin.id.toString(),
                toCoinId: toCoin.id.toString(),
                fromAmount: limitedFromValue,
            }).value,
        );
    };

    const getTitle = () => {
        let result = '';
        if (buySellCoinValues?.isSelling) result += 'Vender ';
        else result += 'Comprar ';
        const foundCoin = coins?.find(
            (c) => c.id.toString() === buySellCoinValues?.cryptoCoin,
        );
        if (!coins || !buySellCoinValues || !foundCoin) return result;
        const obj = CoinComponentObject[foundCoin.tokenCode];
        result += `${obj.text} (${obj.id})`;
        return result;
    };

    const getSubtitle = () => {
        if (!fromCoin || !buySellCoinValues) return '';

        let result = 'Usar todos mis fondos';
        const maxBalance = dotAndComma(
            _getMaxBalance(fromCoin.id.toString()).toString(),
        );

        // if coinType is ARS or USDT, fixed 2 decimals
        if (
            fromCoin.tokenCode === COIN_TYPES.ARS ||
            fromCoin.tokenCode === COIN_TYPES.USDT
        )
            return (result += ` (${twoDecimals(maxBalance)} ${
                fromCoin.tokenCode
            })`);

        result += ` (${maxBalance.toString()} ${fromCoin.tokenCode})`;
        return result;
    };

    const getEquivalent = () => {
        if (!formik.values.toAmountEquivalent)
            return `≈ 0,00 ` + toCoin?.tokenCode;
        // if coinType is ARS or USDT, fixed 2 decimals
        if (
            toCoin?.tokenCode === COIN_TYPES.ARS ||
            toCoin?.tokenCode === COIN_TYPES.USDT
        )
            return (
                `≈ ${formatNumberWithCommas(
                    formik.values.toAmountEquivalent,
                    toCoin.tokenCode,
                    true,
                )} ` + toCoin?.tokenCode
            );

        return `≈ ${dotAndComma(
            Big(formik.values.toAmountEquivalent).toFixed(8),
        )} ${toCoin?.tokenCode}`;
    };

    const resetToAmountEquivalent = () => {
        formik.setFieldValue('toAmountEquivalent', '');
    };

    return (
        <>
            {modalOpen === MODAL_OPEN.BUY_DETAIL && (
                <BuyModal
                    onClose={() =>
                        dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))
                    }
                    hideClose
                    open={modalOpen === MODAL_OPEN.BUY_DETAIL}
                    title={getTitle()}
                    colorTop
                    subtitle={
                        <P
                            color='primary'
                            style={{
                                fontSize: '14px',
                                marginBottom: '2px',
                                textDecoration: 'underline',
                            }}
                            onClick={() => {
                                if (!fromCoin) return;
                                handleFromChange(
                                    _getMaxBalance(
                                        fromCoin.id.toString(),
                                    ).toString(),
                                );
                            }}
                        >
                            {getSubtitle()}
                        </P>
                    }
                >
                    <Row style={{ display: 'flex' }}>
                        <Col className='col-12'>
                            <Form onSubmit={formik.handleSubmit}>
                                <InputAmount
                                    value={formik.values.fromAmount}
                                    onChange={handleFromChange}
                                    suffix={fromCoin?.tokenCode}
                                />
                            </Form>
                        </Col>
                    </Row>
                    <P className='mt-3 mb-4' style={{ fontWeight: '500' }}></P>
                    <div style={{ margin: '10px' }}>
                        <P
                            color='info'
                            style={{ fontWeight: '400', lineHeight: '1.5' }}
                        >
                            {getEquivalent()}
                        </P>
                    </div>
                    <Button
                        disabled={
                            formik.values.fromAmount === '' ||
                            formik.values.fromAmount === '0'
                        }
                        className='m-3'
                        onClick={() => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) =>
                                    (tpp.quoteCode === fromCoin?.tokenCode &&
                                        tpp.baseCode === toCoin?.tokenCode) ||
                                    (tpp.baseCode === fromCoin?.tokenCode &&
                                        tpp.quoteCode === toCoin?.tokenCode),
                            );
                            if (
                                !fromCoin ||
                                !toCoin ||
                                !foundTPP ||
                                !buySellCoinValues
                            )
                                return;
                            dispatch(
                                addExchangeQuote({
                                    amount: formik.values.fromAmount,
                                    tokenpair_id: foundTPP.tokenpairId,
                                    order_side: buySellCoinValues.isSelling
                                        ? ORDER_SIDE.SELL
                                        : ORDER_SIDE.BUY,
                                    amount_in: '',
                                    symbol: '',
                                    executeAfterConfirm: null,
                                }),
                            )
                                .unwrap()
                                .then(() => {
                                    formik.setFieldValue('fromAmount', '');
                                    dispatch(
                                        modalActions.setModalOpen(
                                            MODAL_OPEN.BUY_CONFIRM,
                                        ),
                                    );
                                    resetToAmountEquivalent();
                                })
                                .catch((err) => {
                                    toast.error(err.message);
                                });
                        }}
                    >
                        Continuar
                    </Button>
                    <P
                        color='danger'
                        style={{
                            textDecoration: 'underline',
                            marginBottom: '10px',
                        }}
                    >
                        <NavLink
                            onClick={() => {
                                dispatch(
                                    quotationActions.setBuyCoinValues({
                                        from: '',
                                        to: '',
                                        fromValue: '',
                                        toValue: '',
                                    }),
                                );
                                formik.setFieldValue('fromAmount', '');
                                dispatch(
                                    modalActions.setModalOpen(MODAL_OPEN.NONE),
                                );
                                resetToAmountEquivalent();
                            }}
                            to='#'
                            style={{ color: '#d43554' }}
                        >
                            Cancelar
                        </NavLink>
                    </P>
                    <P
                        style={{
                            fontWeight: '400',
                            margin: '5px',
                            lineHeight: '1.5',
                        }}
                    >
                        En el proximo paso vas a poder revisar la cotizacion
                        actual antes de confirmar la compra
                    </P>
                </BuyModal>
            )}
        </>
    );
};
