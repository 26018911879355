import { Button, FormInput, P, ToastBanner } from '@common';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { SessionCard } from '../components/SessionCard';
import { useEffect, useState } from 'react';
import './Login2FA.scss';
import login2FAImage from '../../../assets/images/login_2fa_side_image.png';
import { useAppDispatch, useAppSelector } from 'redux/store';
import QRCode from 'react-qr-code';
import { getTOTP, loginTOTP } from '../sessionService';
import { REQUEST_STATE } from 'redux/types';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import { ROUTE_PATH } from 'layout/routes/const';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
    ACCESS_TOKEN,
    AUTHENTICATED,
    FIRST_TIME_2FA,
    REFRESH_TOKEN,
} from 'utils/axios/index';

export interface Login2faValues {
    pinCode: string;
}

const ValidationSchema = Yup.object().shape({
    pinCode: Yup.string()
        .length(6, 'Este campo es requerido')
        .required('Este campo es requerido'),
});

export const Login2FA = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { requestStates, totpCode } = useAppSelector(
        (state) => state.session,
    );
    const loginTOTPState = requestStates.loginTOTP;
    const [forgotQR, setForgotQR] = useState(false);

    const formik = useFormik({
        initialValues: {
            pinCode: '',
        },
        onSubmit: () => {
            dispatch(
                loginTOTP({
                    pinCode: formik.values.pinCode,
                }),
            )
                .unwrap()
                .then((res: any) => {
                    navigate(ROUTE_PATH.ROOT);
                })
                .then(() => navigate(ROUTE_PATH.ROOT))
                .catch(() => toast.error('Código ingresado incorrecto.'));
        },
        validationSchema: ValidationSchema,
    });

    const firstTime2FA = localStorage.getItem(FIRST_TIME_2FA) === 'true';
    const showQR = firstTime2FA || forgotQR;

    useEffect(() => {
        if (showQR) {
            dispatch(getTOTP())
                .unwrap()
                .catch(() => {
                    localStorage.removeItem(ACCESS_TOKEN);
                    localStorage.removeItem(REFRESH_TOKEN);
                    localStorage.setItem(AUTHENTICATED, 'false');
                    navigate('/login');
                });
        }
    }, [showQR]);

    return (
        <div className='login-card bisbull-login-2fa'>
            <SessionCard
                title='Ingresar'
                subtitle=''
                src={login2FAImage}
                onSubmit={formik.handleSubmit}
            >
                {showQR && (
                    <>
                        <Row>
                            <ToastBanner
                                show
                                message='Escanea el siguiente código QR usando la aplicación Google Authenticator en tu teléfono móvil.'
                            />
                        </Row>
                        <Row className='mb-4 mt-4'>
                            <Col className='d-flex justify-content-center'>
                                {totpCode ? (
                                    <QRCode value={totpCode} size={200} />
                                ) : (
                                    <LoaderWheel
                                        style={{ width: 200, height: 200 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col>
                        <FormInput
                            label='Ingresá el código de seguridad (2FA) generado'
                            name='pinCode'
                            type='pin-password'
                            value={formik.values.pinCode}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            error={formik.errors.pinCode}
                        />
                    </Col>
                </Row>
                <Row className='buttons-container mt-4 justify-content-center'>
                    <Button
                        type='submit'
                        loading={loginTOTPState === REQUEST_STATE.LOADING}
                        style={{ height: 45 }}
                    >
                        Ingresar
                    </Button>
                </Row>
                <Row className='justify-content-center mt-3'>
                    <P>
                        ¿No tenés una cuenta?
                        <NavLink className='ms-2 pl-1' to='/registro'>
                            Crear
                        </NavLink>
                    </P>
                </Row>
                {!firstTime2FA && (
                    <Row className='justify-content-center m-3'>
                        <P
                            color='primary'
                            style={{
                                fontWeight: 'bold',
                                fontSize: 13,
                                cursor: 'pointer',
                            }}
                            onClick={() => setForgotQR(true)}
                        >
                            Olvidé mi código
                        </P>
                    </Row>
                )}
            </SessionCard>
        </div>
    );
};
